import styled from "styled-components";
import { DhlLoader } from "@dhl-official/react-library";

// #region Component Styles
const ButtonLoader = styled(DhlLoader).attrs({
  size: "sm",
})`
  & div.sm {
    width: 25px;
    height: 25px;

    &::after,
    &::before {
      box-sizing: border-box;
    }
  }
`;
// #endregion

export default ButtonLoader;
