import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Modal from "../../Modal";
import CallbackForm from "./CallbackForm/container";
import { STYLES_VIEWPORTS } from "../../../utils/constants";

// #region Component Styles
const StyledModal = styled(Modal)`
  &.dhl-cj-fs {
    direction: ltr;
    max-height: 100%;
    max-width: 100%;
    min-height: auto;
    overflow: auto;
    padding: 30px 20px;
    width: 100%;
    width: 95vw;

    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      max-width: 900px;
      padding-bottom: 50px;
      padding: 60px;
    }
  }
`;

const Content = styled.div`
  font-size: 14px;
  margin: -30px -20px;
  max-height: 100vh;
  padding: 30px 20px;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    margin-bottom: -50px;
    margin: -60px;
    padding-bottom: 50px;
    padding: 60px;
  }
`;

const CopyContainer = styled.div`
  margin: var(--dui-size-space-7x) auto 0;
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    p {
      margin-top: 0;
    }
  }
`;
// #endregion

const ModalConnectorSelector = ({ intl, isOpen, onClose }) => {
  return (
    <StyledModal
      className="dhl-cj-fs"
      closeButtonAriaLabel={intl.formatMessage({
        id: "FS2.ContentModal.close",
      })}
      contentAriaLabel={intl.formatMessage({ id: "FS2.Connectors.modalTitle" })}
      isOpen={isOpen}
      onClose={onClose}
      overlayColor="rgba(204, 204, 204, 0.9)"
      shouldShowCloseButton
    >
      <Content>
        <CopyContainer>
          <CallbackForm onGoToOptionsStep={onClose} />
        </CopyContainer>
      </Content>
    </StyledModal>
  );
};

ModalConnectorSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalConnectorSelector;
