// 9fbef606107a605d69c0edbcd8029e5d

import {
  HIDE_MODAL_CONNECTOR_SELECTOR,
  HIDE_MODAL_ERROR,
  HIDE_MODAL_ERROR_CALLBACK_REMOTE,
  OPEN_MODAL_CONNECTOR_SELECTOR,
} from "../../../../actions";

export const hideModalError = () => ({
  type: HIDE_MODAL_ERROR,
});

export const hideModalErrorCallbackRemote = () => ({
  type: HIDE_MODAL_ERROR_CALLBACK_REMOTE,
});

export const openModalConnectorSelector = () => ({
  type: OPEN_MODAL_CONNECTOR_SELECTOR,
});

export const hideModalConnectorSelector = () => ({
  type: HIDE_MODAL_CONNECTOR_SELECTOR,
});
