import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { DhlHeadline } from "@dhl-official/react-library";
import { THEMES } from "../../utils/constants";

// #region Component Styles
const getMyDHLPlusThemeStyles = () => css`
  & h3 {
    color: var(--mydhlplus-font-color);
    font-size: var(--mydhlplus-copy-font-size);
    font-weight: var(--dui-font-weight-normal);
    line-height: var(--mydhlplus-copy-line-height);
    font-stretch: normal;
  }
`;

const StyledHeadline = styled(DhlHeadline).attrs({
  designLevel: 4,
  noMargin: true,
  tag: "h3",
  textAlign: "center",
})`
  display: block;
  margin: 0 auto;
  max-width: 550px;

  & h3 {
    margin-bottom: ${({ $addMarginBottom }) =>
      $addMarginBottom && "var(--dui-size-space-10x)"};
    padding-bottom: ${({ $addPadding }) =>
      $addPadding && "var(--dui-size-space-3x)"};
    padding-top: ${({ $addPadding }) =>
      $addPadding && "var(--dui-size-space-7x)"};
  }

  ${({ $theme }) => $theme === THEMES.MYDHLPLUS && getMyDHLPlusThemeStyles()};
`;
// #endregion

const ViewHeadline = ({
  addPadding,
  addMarginBottom,
  children,
  dataTestid,
  id,
  theme,
}) => (
  <StyledHeadline
    $addPadding={addPadding}
    $addMarginBottom={addMarginBottom}
    dataTestid={dataTestid}
    id={id}
    $theme={theme}
  >
    {children}
  </StyledHeadline>
);

ViewHeadline.propTypes = {
  addMarginBottom: PropTypes.bool,
  addPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

export default ViewHeadline;
