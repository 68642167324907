// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import { TRACK_EVENT } from "../../../actions";
import { SUCCESS_MODAL_VARIATIONS } from "../../../utils/constants";

const getInitialState = (trackingId) => ({
  perfFirstConsistentlyInteractiveinMs: 0,
  perfFirstContentfulPaint: 0,
  perfFirstMeaningfulPaint: 0,
  perfFirstPaint: 0,
  perfLongtask: 0,
  perfPerceivedPageLoadTime: 0,
  perfRequestTime: 0,
  perfResponseTime: 0,
  perfTotalRequestResponseTime: 0,
  regularShipmentLPStep1NextClicked: "",
  regularShipmentLPStep2NextClicked: "",
  regularShipmentLPStep2PreviousClicked: false,
  regularShipmentLPStep3PreviousClicked: false,
  regularShipmentLPStep3SubmitClicked: "",
  regularShipmentNoBusinessUnitsModalCancelButtonClicked: "",
  regularShipmentNoBusinessUnitsModalContactUsClicked: false,
  regularShipmentNoBusinessUnitsModalRetryButtonClicked: "",
  selfOnboardingButtonClicked: "",
  successModalClosedAt: "",
  successModalselfOnboardingBUs: null,
  successModalShownAt: "",
  successModalVariationShown: SUCCESS_MODAL_VARIATIONS.DEFAULT,
  trackingId,
});

const reducer = (trackingId) => (
  state = getInitialState(trackingId),
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case TRACK_EVENT:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
