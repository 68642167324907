import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// #region Component Styles
const Container = styled.span`
  display: inline-block;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-sm);
  margin: 0;
  vertical-align: middle;
`;

const Mark = styled.span`
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-sm);
  font-weight: 600;
`;
// #endregion

const getHighlightedText = (content, term) => {
  const startIndex = content.toLowerCase().indexOf(term.toLowerCase());
  const endIndex = startIndex + term.length;

  if (startIndex === -1) {
    return content;
  }

  return (
    <Fragment>
      {content.slice(0, startIndex)}
      <Mark>{content.slice(startIndex, endIndex)}</Mark>
      {getHighlightedText(content.slice(endIndex), term)}
    </Fragment>
  );
};

const Highlight = ({ content, term }) => (
  <Container>
    {term.length > 0 ? getHighlightedText(content, term) : content}
  </Container>
);

Highlight.propTypes = {
  content: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
};

export default Highlight;
