import PropTypes from "prop-types";
import styled from "styled-components";
import getFlagForCountry from "./flag-for-country";
import flagsImage from "../../assets/images/flags.webp";

// #region Component Styles
const Flag = styled.i.attrs({ "aria-hidden": true })`
  background-image: url(${flagsImage});
  background-repeat: no-repeat;
  background-size: 662px;
  border: solid 1px var(--dui-color-gray-300);
  box-sizing: content-box;
  display: inline-block;
  height: 20px;
  margin-right: var(--dui-size-space-3x);
  width: 29px;

  ${({ $country }) => getFlagForCountry($country)};
`;
// #endregion

Flag.propTypes = {
  className: PropTypes.string,
  $country: PropTypes.string.isRequired,
};

export default Flag;
