// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { DhlCard } from "@dhl-official/react-library";
import styled, { keyframes } from "styled-components";
import { PROFILES, STYLES_VIEWPORTS } from "../../utils/constants";

// #region Component Styles
const LOADING = keyframes`
0% {
  transform: translateX(-150%);
}

100% {
  transform: translateX(250%);
}
`;

const Container = styled(DhlCard)`
  height: 430px;
  padding: 35px 40px;
  margin: auto;
  max-width: 940px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 16px;
`;

const Text = styled.div`
  background-color: var(--dui-color-gray-100);
  height: 20px;
  border-radius: 4px;
  margin: 0 0 10px auto;
`;

const Icon = styled.div`
  background-color: var(--dui-color-gray-100);
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const Title = styled.div`
  background-color: var(--dui-color-gray-100);
  width: ${(props) => props.width || "30%"};
  height: ${(props) => props.height || "42px"};
  margin: 10px auto 35px auto;
  border-radius: 4px;
`;

const Description = styled.div`
  background-color: var(--dui-color-gray-100);
  height: 25px;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 25%;
`;

const Activity = styled.div`
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  animation: ${LOADING} 1s infinite;
`;

const ContainerFSX = styled.div`
  display: flex;
  margin-top: 130px;

  @media screen and (max-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    display: block;
  }
`;

const CardFSX = styled(DhlCard)`
  height: 100%;
  width: 100%;
  margin-right: 15px;
  @media screen and (max-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    margin-bottom: 15px;
  }
`;

const ButtonFSX = styled.div`
  background-color: var(--dui-color-gray-100);
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin: auto;
  margin-top: 40px;
`;

const TitleFSX = styled.div`
  background-color: var(--dui-color-gray-100);
  height: 30px;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 70%;
`;

const TextFSX = styled.div`
  background-color: var(--dui-color-gray-100);
  height: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 5px;
`;

const IconFSX = styled.div`
  background-color: var(--dui-color-gray-100);
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
`;

// #endregion

const LoadingSkeletonServices = ({ profile }) => {
  return (
    <div>
      {profile === PROFILES.FS && (
        <div>
          <Title width="60%" height="60px">
            <Activity />
          </Title>
          <Container>
            <Title>
              <Activity />
            </Title>
            <Grid>
              <div>
                <Icon>
                  <Activity />
                </Icon>
                <Description>
                  <Activity />
                </Description>
              </div>

              <div>
                {[1, 2, 3, 4, 5].map((n) => (
                  <Text key={n}>
                    <Activity />
                  </Text>
                ))}
              </div>
            </Grid>
          </Container>
        </div>
      )}
      {profile === PROFILES.FSX && (
        <ContainerFSX>
          {[1, 2, 3].map((n) => (
            <CardFSX key={n}>
              <IconFSX>
                <Activity />
              </IconFSX>
              <TitleFSX>
                <Activity />
              </TitleFSX>
              <TextFSX />
              <TextFSX />
              <ButtonFSX>
                <Activity />
              </ButtonFSX>
            </CardFSX>
          ))}
        </ContainerFSX>
      )}
    </div>
  );
};

LoadingSkeletonServices.propTypes = {
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
};

export default LoadingSkeletonServices;
