// 9fbef606107a605d69c0edbcd8029e5d

import { combineReducers } from "redux";
import featureFlagsReducer, * as featureFlags from "./feature-flags";
import leadMetaValuesReducer, * as leadMetaValues from "./lead-meta-values";
import pageNameLegacyReducer, * as pageNameLegacy from "./page-name-legacy";
import trackingReducer, * as tracking from "./tracking";
import versionReducer, * as version from "./version";

export default (_version, trackingId, _pageNameLegacy) =>
  combineReducers({
    featureFlags: featureFlagsReducer,
    leadMetaValues: leadMetaValuesReducer,
    pageNameLegacy: pageNameLegacyReducer(_pageNameLegacy),
    tracking: trackingReducer(trackingId),
    version: versionReducer(_version),
  });

export const getFeatureFlags = (state) => featureFlags.get(state.featureFlags);

export const getLeadMetaValues = (state) =>
  leadMetaValues.get(state.leadMetaValues);

export const getPageNameLegacy = (state) =>
  pageNameLegacy.get(state.pageNameLegacy);

export const getTracking = (state) => tracking.get(state.tracking);

export const getVersion = (state) => version.get(state.version);
