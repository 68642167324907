import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { STEPS } from "../../../utils/constants";

const Container = styled.ul`
  display: none;
`;

const Step = styled.li``;

// ANALYTICS HACK: This component here is needed by ANALYTICS. Do not remove!
const BackwardsCompatibleTracking = ({ activeStep }) => (
  <Container>
    <Step
      id="regular-shipment-form-step-shipment-products"
      aria-hidden={activeStep !== STEPS.PRODUCT}
    />

    <Step
      id="regular-shipment-form-step-shipment-scale"
      aria-hidden={activeStep !== STEPS.PRODUCT_DETAILS}
    />

    <Step
      id="regular-shipment-form-step-contact-information"
      aria-hidden={![STEPS.CONTACT_FORM, STEPS.CONNECTORS].includes(activeStep)}
    />
  </Container>
);

BackwardsCompatibleTracking.propTypes = {
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
};

export default memo(BackwardsCompatibleTracking);
