// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as reducers from "../../../reducers";
import * as actions from "../../../reducers/actions";
import { MODALS } from "../../../utils/constants";
import ModalError from ".";

const mapStateToProps = (state, { intl }) => ({
  headline: intl.formatMessage({ id: "FS2.ModalError.headline" }),
  isOpen: reducers.getShownModal(state) === MODALS.ERROR,
  subline: intl.formatMessage({ id: "FS2.ModalError.subHeadline" }),
});

const mapDispatchToProps = {
  onClose: actions.hideModalError,
  onRetry: actions.postLeadEffect,
};

export default compose(
  memo,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ModalError);
