// 9fbef606107a605d69c0edbcd8029e5d

import React, { Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import StepIndicator from "./StepIndicator";
import StepNavigator from "./StepNavigator/container";
import Product from "../Steps/Product/container";
import ProductDetails from "../Steps/ProductDetails/container";
import BackwardsCompatibleTracking from "./BackwardsCompatibleTracking";
import { trackContentInteraction } from "../../utils/track-utf";
import LoadingSkeletonServices from "../LoadingSkeletonServices";

import {
  A11Y_CURRENT_STEP_ELEMENT,
  MAIN_FORM_ID,
  PROFILES,
  STEPS,
  THEMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
} from "../../utils/constants";
import ScreenReaderAnnouncement from "./ScreenReaderAnnouncement";

// #region Component Styles
const Container = styled.form.attrs({ noValidate: true, id: MAIN_FORM_ID })`
  background-color: var(--fs-form-container-background-color);
  margin-top: ${({ $isThankYouStep }) => ($isThankYouStep ? "80px" : 0)};
`;

const getMyDHLPlusThemeStyles = () => css`
  padding-top: 0;
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  padding: var(--dui-size-space-10x);
  ${({ $theme }) => $theme === THEMES.MYDHLPLUS && getMyDHLPlusThemeStyles()};
`;

// #endregion

const Intro = React.lazy(() =>
  import(/* webpackChunkName: "bundle-secondary" */ "../Steps/Intro/container")
);
const Contact = React.lazy(() =>
  import(/* webpackChunkName: "bundle-secondary" */ "../ContactForm/container")
);
const Connectors = React.lazy(() =>
  import(
    /* webpackChunkName: "bundle-secondary" */ "../Steps/Connectors/container"
  )
);
const Services = React.lazy(() =>
  import(
    /* webpackChunkName: "bundle-secondary" */ "../Steps/Services/container"
  )
);
const ThankYou = React.lazy(() =>
  import(
    /* webpackChunkName: "bundle-secondary" */ "../Steps/ThankYou/container"
  )
);

const getStep = (activeStep) => {
  switch (activeStep) {
    case STEPS.INTRO:
      return <Intro />;

    case STEPS.PRODUCT:
      return <Product />;

    case STEPS.PRODUCT_DETAILS:
      return <ProductDetails />;

    case STEPS.CONTACT_FORM:
      return <Contact />;

    case STEPS.CONNECTORS:
      return <Connectors />;

    case STEPS.SERVICES:
      return <Services />;

    case STEPS.THANK_YOU:
      return <ThankYou />;

    default:
      return null;
  }
};

const getStepNavigatorId = (activeStep) => {
  switch (activeStep) {
    case STEPS.INTRO:
      return "regular-shipment-form-step-intro";

    case STEPS.PRODUCT:
      return "regular-shipment-form-step-shipment-products";

    case STEPS.PRODUCT_DETAILS:
      return "regular-shipment-form-step-shipment-products-details";

    case STEPS.CONTACT_FORM:
      return "regular-shipment-form-step-contact-information";

    case STEPS.CONNECTORS:
      return "regular-shipment-form-step-connectors";

    case STEPS.THANK_YOU:
      return "regular-shipment-form-step-thank-you";

    case STEPS.SERVICES:
      return "regular-shipment-form-step-services";

    default:
      return undefined;
  }
};

const getDecibelFormIdStep = (activeStep) => {
  switch (activeStep) {
    case STEPS.PRODUCT_DETAILS:
      return "FS | Shipping Profile | Select Scale Step (Step 1)";

    case STEPS.PRODUCT:
      return "FS | Shipping Profile | Select Product Step (Step 2)";

    case STEPS.SERVICES:
      return "FS | Shipping Profile | Services (Step 3)";

    case STEPS.CONNECTORS:
      return "FS | Shipping Profile | Connectors (Step 3.2)";

    case STEPS.CONTACT_FORM:
      return "FS | Shipping Profile | Contact Information Step (Step 4)";

    default:
      return undefined;
  }
};

const triggerInputTrackingEvent = (profile, activeStep) => {
  trackContentInteraction({
    context: profile,
    name: UTF_EVENT_NAMES.FORM_INPUT,
    type: UTF_EVENT_TYPES.INPUT_FIELD,
    interaction: UTF_EVENT_INTERACTIONS.INPUT,
    position: activeStep,
  });
};

const triggerClickTrackingEvent = () => {
  trackContentInteraction({
    context: document.getElementById(A11Y_CURRENT_STEP_ELEMENT)?.dataset
      ?.profile,
    name: UTF_EVENT_NAMES.UNSAVED_CHANGES_DIALOG,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: document.getElementById(A11Y_CURRENT_STEP_ELEMENT)?.dataset
      ?.stepid,
  });
};

const focusOnCurrentStepIndicatorEffect = () => {
  document
    .getElementById(A11Y_CURRENT_STEP_ELEMENT)
    ?.focus({ preventScroll: true });
};

const handleLeavePage = (e) => {
  e.preventDefault();
  e.returnValue = true;
  triggerClickTrackingEvent();
};

const MultiStepForm = ({
  activeStep,
  activeStepNumber,
  applicationRef,
  contentPrefix,
  goToNextStep,
  goToPreviousStep,
  hasUserInteraction,
  isRtlLayout,
  numberOfSteps,
  profile,
  shouldHideScreenReaderAnnouncement,
  shouldHideStepIndicator,
  stepsHistory,
  theme,
}) => {
  useEffect(() => {
    if (stepsHistory.length > 1) {
      applicationRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
    if (activeStep === STEPS.THANK_YOU) {
      window.removeEventListener("beforeunload", handleLeavePage);
    }
  }, [activeStep]);

  useEffect(() => {
    if (hasUserInteraction) {
      window.addEventListener("beforeunload", handleLeavePage);
      triggerInputTrackingEvent(profile, activeStep);
    }
  }, [hasUserInteraction]);

  return (
    <Container
      data-di-form-id={getDecibelFormIdStep(activeStep)}
      data-di-form-track
      $isThankYouStep={activeStep === STEPS.THANK_YOU}
      onSubmit={(e) => {
        e.preventDefault();

        focusOnCurrentStepIndicatorEffect();

        // ANALYTICS HACK: This `setTimeout` here is needed by ANALYTICS. Do not remove!
        setTimeout(() => {
          goToNextStep();
        }, 10);
      }}
    >
      {!shouldHideScreenReaderAnnouncement && (
        <ScreenReaderAnnouncement
          activeStep={activeStep}
          activeStepNumber={activeStepNumber}
          numberOfSteps={numberOfSteps}
          profile={profile}
        />
      )}
      {!shouldHideStepIndicator && (
        <StepIndicator
          activeStepNumber={activeStepNumber}
          contentPrefix={contentPrefix}
          numberOfSteps={numberOfSteps}
          profile={profile}
          activeStep={activeStep}
        />
      )}

      <Content data-testid={getStepNavigatorId(activeStep)} $theme={theme}>
        <Suspense fallback={<LoadingSkeletonServices profile={profile} />}>
          {getStep(activeStep)}
        </Suspense>
      </Content>

      <StepNavigator
        activeStep={activeStep}
        isRtlLayout={isRtlLayout}
        contentPrefix={contentPrefix}
        id={getStepNavigatorId(activeStep)}
        onPrevious={() => {
          focusOnCurrentStepIndicatorEffect();

          // ANALYTICS HACK: This `setTimeout` here is needed by ANALYTICS. Do not remove!
          setTimeout(() => {
            goToPreviousStep();
          }, 10);
        }}
        profile={profile}
      />

      <BackwardsCompatibleTracking activeStep={activeStep} />
    </Container>
  );
};

MultiStepForm.propTypes = {
  applicationRef: PropTypes.object.isRequired,
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  /** Which is the current step index? Keep in mind this is indexed from 1 */
  activeStepNumber: PropTypes.number,
  contentPrefix: PropTypes.string.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
  hasUserInteraction: PropTypes.bool.isRequired,
  isRtlLayout: PropTypes.bool.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  shouldHideScreenReaderAnnouncement: PropTypes.bool.isRequired,
  shouldHideStepIndicator: PropTypes.bool.isRequired,
  stepsHistory: PropTypes.array.isRequired,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

export default MultiStepForm;
