import React from "react";
import { DhlButton } from "@dhl-official/react-library";
import { ChevronBack as IconChevronBack } from "@dhl-official/icons";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContactForm from "../../../ContactForm/container";
import ViewHeadline from "../../../ViewHeadline/container";
import ButtonLoader from "../../../ButtonLoader";
import { trackContentInteraction } from "../../../../utils/track-utf";
import {
  PROFILES,
  STEPS,
  STYLES_VIEWPORTS,
  THEMES,
  UTF_EVENT_CONTEXTS,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
  UTF_EVENT_TYPES,
} from "../../../../utils/constants";

// #region Component Styles
const Form = styled.form.attrs({
  noValidate: true,
  "data-testid": "regular-shipment-form-step-contact-information",
})``;

const Container = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(var(--dui-size-space-7x) * 2) 0;
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row-reverse;
  }
`;

const getStyleForDisabledButton = ($visuallyDisabled) => {
  return (
    $visuallyDisabled &&
    css`
      & > .styled.primary:is(button) {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: unset;
        color: var(--dui-color-white-500);
      }
    `
  );
};

const NextButton = styled(DhlButton)`
  & button {
    cursor: pointer;
    margin: var(--dui-size-space-3x);
    min-width: 70%;
    width: auto;
    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      min-width: 210px;
    }
  }

  ${({ $visuallyDisabled }) => getStyleForDisabledButton($visuallyDisabled)};
`;

const PreviousButton = styled.button.attrs({
  type: "button",
})`
  color: var(--dui-color-black-400);
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  border: solid 2px transparent;
  display: flex;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  padding: 10px 30px 11px 30px;
  transition: border-color var(--dui-duration-default);

  &:focus-visible {
    border-color: var(--dui-color-black-400);
  }
`;

const getMyDHLPlusThemeStyles = () => css`
  color: var(--mydhlplus-color-blue-400);
`;

const BackArrow = styled(IconChevronBack)`
  color: var(--dui-color-red-500);
  height: 20px;
  width: 20px;
  margin-right: 5px;
  transform: rotate(
    ${({ $isRtlLayout }) => ($isRtlLayout ? "180deg" : "0deg")}
  );

  ${({ $theme }) => $theme === THEMES.MYDHLPLUS && getMyDHLPlusThemeStyles()};
`;
// #endregion

const triggerSubmitTrackingEvent = (context) => {
  trackContentInteraction({
    context,
    name: UTF_EVENT_NAMES.FORM_SUBMIT,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: UTF_EVENT_POSITIONS.CONNECTORS,
  });
};

const triggerClickTrackingEvent = (profile, name, activeStep) => {
  trackContentInteraction({
    context: profile,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: activeStep,
  });
};

const CallbackForm = ({
  activeStep,
  callbackRemoteDisplay,
  goToNextStep,
  isNextStepEnabled,
  isRtlLayout,
  isWaitingForEndpoint,
  onGoToOptionsStep,
  profile,
  schedulerDisplay,
  theme,
}) => {
  const getFormTitle = () => {
    if (schedulerDisplay) {
      return (
        <FormattedMessage id="FS2.Connectors.callbackSchedule_formTitle" />
      );
    }
    if (callbackRemoteDisplay) {
      return <FormattedMessage id="FS2.Connectors.callbackRemote_formTitle" />;
    }
    return <FormattedMessage id="FS2.Connectors.contactForm_formTitle" />;
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        if (schedulerDisplay) {
          triggerSubmitTrackingEvent(UTF_EVENT_CONTEXTS.SCHEDULED);
        } else if (callbackRemoteDisplay) {
          triggerSubmitTrackingEvent(UTF_EVENT_CONTEXTS.CALLBACK_REMOTE);
        } else {
          triggerSubmitTrackingEvent(UTF_EVENT_CONTEXTS.DEFAULT);
        }

        // ANALYTICS HACK: This `setTimeout` here is needed by ANALYTICS. Do not remove!
        setTimeout(() => {
          goToNextStep();
        }, 10);
      }}
    >
      <ContactForm
        isBorderLess
        title={<ViewHeadline>{getFormTitle()}</ViewHeadline>}
      />

      <Container>
        <NextButton
          $visuallyDisabled={!isNextStepEnabled}
          isDisabled={isWaitingForEndpoint ? true : null}
          dataTestid="btn-callback-submit"
          dataTracking="regular-shipment-form-step-contact-information-button-proceed"
          name="regular-shipment-form-step-contact-information-button-proceed"
          type="submit"
          clickEvent={() =>
            triggerClickTrackingEvent(profile, UTF_EVENT_NAMES.NEXT, activeStep)
          }
        >
          <div>
            {!isWaitingForEndpoint ? (
              <FormattedMessage id="FS2.Connectors.callbackSubmit" />
            ) : (
              <ButtonLoader />
            )}
          </div>
        </NextButton>
        <PreviousButton
          data-testid="btn-callback-back"
          data-tracking="regular-shipment-form-step-contact-information-button-previous"
          name="regular-shipment-form-step-contact-information-button-previous"
          onClick={() => {
            onGoToOptionsStep();
            triggerClickTrackingEvent(
              profile,
              UTF_EVENT_NAMES.PREVIOUS,
              activeStep
            );
          }}
        >
          <BackArrow $isRtlLayout={isRtlLayout} $theme={theme} />
          <FormattedMessage id="FS2.Connectors.callbackBack" />
        </PreviousButton>
      </Container>
    </Form>
  );
};

CallbackForm.propTypes = {
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  callbackRemoteDisplay: PropTypes.bool.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  isNextStepEnabled: PropTypes.bool,
  isRtlLayout: PropTypes.bool.isRequired,
  isWaitingForEndpoint: PropTypes.bool,
  onGoToOptionsStep: PropTypes.func.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  schedulerDisplay: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

export default CallbackForm;
