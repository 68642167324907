// 9fbef606107a605d69c0edbcd8029e5d

import { compose, identity, keys, sortBy } from "ramda";
import {
  API_REQUEST_HEADERS,
  API_REQUEST_HEADER_VALUES,
  CALLBACK_REMOTE_SLOTS_HTTP_STATUS_CODES,
  DEFAULT_PROFILE,
  DEV_MODES,
} from "./constants";
import normalizeUrl from "./normalize-url";
import { isDevMode } from ".";

const decodeTranslation = (translation) =>
  Object.entries(translation).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value === " " ? "" : value,
    }),
    {}
  );

const normalizeBusinessUnitDetails = ({ businessUnitId: id, ...details }) => ({
  id,
  ...details,
});

/**
 * Let's reorder the slots API answer a bit to an object
 * with sorted entries of dates containing arrays of possible times
 */
const processCallbackRemoteSlots = (data) => {
  const slotList = data.reduce((acc, entry) => {
    const [slotDate, slotTime] = Object.entries(entry)[0];
    return { ...acc, [slotDate]: [...(acc[slotDate] || []), slotTime] };
  }, {});
  const sortedDays = compose(sortBy(identity), keys)(slotList);
  return sortedDays.reduce((acc, slotDate) => {
    return { ...acc, [slotDate]: slotList[slotDate] };
  }, {});
};

const loadTranslation = (locale) => {
  const [language, country] = locale.toLowerCase().split("-");

  if (isDevMode(DEV_MODES.COPY)) {
    return import("../assets/translations/developer.json").then(
      (module) => module.default
    );
  }

  return fetch(
    normalizeUrl(
      `${process.env.COPY_BASE_URL}/${country}/${country}-${language}/content-api/frequent-shipment.data.json`
    )
  )
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json())
    .then((res) => decodeTranslation(res.labels));
};

const loadConfiguration = (country, profile, integration) => {
  if (isDevMode(DEV_MODES.SETTINGS)) {
    return import("../assets/configurations/developer.json").then(
      (module) => module.default
    );
  }

  const profileExtension =
    profile && profile !== DEFAULT_PROFILE ? `/profile/${profile}` : "";
  const integrationExtension = integration ? `-${integration}` : "";

  return fetch(
    normalizeUrl(
      `${process.env.API_BASE_URL}/api/configuration/v2/fs/${country}${profileExtension}${integrationExtension}`
    )
  )
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json());
};

const loadSettings = (country) => {
  if (isDevMode(DEV_MODES.BU_SETTINGS)) {
    return import("../assets/business-lead/developer.json").then(
      (module) => module.default
    );
  }

  return fetch(
    normalizeUrl(
      `${process.env.API_BASE_URL}/api/cj/admin/businessLeadConfiguration/${country}`
    )
  )
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json());
};

const loadCallbackRemoteSlots = (options) => {
  const { country, correlationId, timeZone } = options;
  if (isDevMode(DEV_MODES.SLOTS)) {
    return import("../assets/slots/developer.json")
      .then((module) => module.default)
      .then((res) => processCallbackRemoteSlots(res.data));
  }

  return fetch(
    normalizeUrl(
      `${
        process.env.API_BASE_URL
      }/api/regularshipment/v2/slots?countryCode=${country}&timeZone=${encodeURIComponent(
        timeZone
      )}`
    ),
    {
      method: "GET",
      headers: {
        [API_REQUEST_HEADERS.APP_CODE]: API_REQUEST_HEADER_VALUES.APP_CODE,
        [API_REQUEST_HEADERS.X_CORRELATION_ID]: correlationId,
      },
    }
  )
    .then((res) =>
      res.ok &&
      res?.status?.toString() ===
        CALLBACK_REMOTE_SLOTS_HTTP_STATUS_CODES.SUCCESS
        ? res
        : Promise.reject(res)
    )
    .then((res) => res.json())
    .then((res) => processCallbackRemoteSlots(res.data));
};

const postLead = (body, correlationIds) =>
  fetch(normalizeUrl(`${process.env.API_BASE_URL}/api/regularshipment/v2`), {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      [API_REQUEST_HEADERS.CONTENT_TYPE]: "application/json",
      [API_REQUEST_HEADERS.X_B3_SPAN_ID]: correlationIds?.spanId || "",
      [API_REQUEST_HEADERS.X_B3_TRACE_ID]: correlationIds?.traceId || "",
      [API_REQUEST_HEADERS.TRACEPARENT]: correlationIds?.traceparent || "",
    },
  })
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json())
    .then((res) => ({
      ...res,
      businessUnitDetails: res.businessUnitDetails.map(
        normalizeBusinessUnitDetails
      ),
    }));

export default {
  loadCallbackRemoteSlots,
  loadConfiguration,
  loadSettings,
  loadTranslation,
  postLead,
};
