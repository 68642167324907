import React from "react";
import PropTypes from "prop-types";
import { DhlHeadline } from "@dhl-official/react-library";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { PROFILES, STYLES_VIEWPORTS, THEMES } from "../../utils/constants";

// #region Component Styles
const LeftAlignContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100vw - (2 * var(--dui-size-space-10x)));

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    max-width: calc(100vw - (4 * var(--dui-size-space-10x)));
  }

  @media screen and (min-width: ${STYLES_VIEWPORTS.XLARGE}) {
    max-width: 119.7rem;
  }
`;

const getMyDHLPlusThemeStyles = () => css`
  & h2 {
    color: var(--mydhlplus-font-color);
    font-size: var(--dui-size-font-2xl);
    font-stretch: normal;
    font-weight: var(--dui-font-weight-normal);
    line-height: 3.8rem;
    margin-bottom: 0;
    padding-bottom: var(--dui-size-space-3x);
    padding-left: var(--dui-size-space-10x);
    padding-right: var(--dui-size-space-10x);
    text-transform: none;
  }
`;

const Title = styled(DhlHeadline).attrs({
  dataTestid: "fs-application-headline",
  designLevel: 2,
  tag: "h2",
  textAlign: "center",
})`
  & h2 {
    color: var(--dui-color-red-500);
    font-size: var(--dui-size-font-8xl);
    line-height: 3.8rem;
    margin-bottom: 0;
    padding-bottom: var(--dui-size-space-10x);
    padding-left: var(--dui-size-space-10x);
    padding-right: var(--dui-size-space-10x);
    text-transform: uppercase;
  }

  ${({ $theme }) => $theme === THEMES.MYDHLPLUS && getMyDHLPlusThemeStyles()};
`;

const LeftAlignTitle = styled(Title).attrs({})`
  & h2 {
    color: var(--dui-color-black-400);
    font-size: 2.8rem;
    line-height: 1.3;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    text-transform: none;

    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      font-size: 3.6rem;
    }
  }
`;

const Separator = styled.hr`
  background-color: var(--dui-color-black-400);
  border: 0;
  box-sizing: content-box;
  color: var(--dui-color-black-400);
  display: block;
  height: 0.2rem;
  margin-bottom: calc(2 * var(--dui-size-space-10x));
  margin-top: var(--dui-size-space-10x);
  text-align: left;
  width: 5.6rem;
`;
// #endregion

const getHeadlineContent = (contentPrefix, isThankYouStep, profile) => (
  <FormattedMessage
    id={`${contentPrefix}.MultiPageForm.headline${
      profile === PROFILES.FSX && isThankYouStep ? "ThankYou" : ""
    }`}
    values={{
      br: <br />,
    }}
  />
);

const ApplicationHeadline = ({
  contentPrefix,
  isThankYouStep,
  leftAlignContent,
  profile,
  theme,
}) =>
  leftAlignContent ? (
    <LeftAlignContainer>
      <LeftAlignTitle>
        {getHeadlineContent(contentPrefix, isThankYouStep, profile)}
      </LeftAlignTitle>
      <Separator />
    </LeftAlignContainer>
  ) : (
    <Title $theme={theme}>
      {getHeadlineContent(contentPrefix, isThankYouStep, profile)}
    </Title>
  );

ApplicationHeadline.propTypes = {
  contentPrefix: PropTypes.string.isRequired,
  leftAlignContent: PropTypes.bool.isRequired,
  isThankYouStep: PropTypes.bool.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

export default ApplicationHeadline;
