import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DhlList, DhlListItem, DhlText } from "@dhl-official/react-library";
import styled from "styled-components";
import CardSelectable from "../../../Cards/Selectable";
import {
  STYLES_CUSTOM_BREAKPOINT_CARDS,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
  UTF_EVENT_TYPES,
} from "../../../../utils/constants";
import { trackContentInteraction } from "../../../../utils/track-utf";

// #region Component Styles
const VisuallyHidden = styled.span.attrs({ className: "visually-hidden" })``;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--dui-size-space-7x) 0;
  height: 100%;

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    flex-direction: row;
  }
`;

const ProductIcon = styled.svg.attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  background-color: var(--dui-color-gray-50);
  height: 67px;
  margin-bottom: var(--dui-size-space-3x);
  margin-top: var(--dui-size-space-10x);
  padding: 10px;
  width: 100%;

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    margin-bottom: 0;
    margin-top: 4px;
    width: 67px;
  }
`;

const ProductLabel = styled(DhlText).attrs({ weight: 700 })`
  & span {
    display: block;
    font-size: 1.5rem;
    word-break: break-word;
    max-width: 157px;
  }

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    width: 165px;
  }
`;

const ProductText = styled.div`
  flex: 1;
  margin-left: 0;

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    margin-left: var(--dui-size-space-7x);
  }
`;

const ProductInfo = styled.div`
  ul {
    line-height: initial;
  }

  li {
    color: var(--dui-color-gray-600);
    font-size: var(--dui-size-font-sm);
    margin-bottom: 0;

    &::before {
      width: 10px;
    }

    &::after {
      background-color: var(--dui-color-gray-600);
      height: 3px;
      margin-left: 0px;
      width: 3px;
    }
  }
`;

const ProductInfoList = styled(DhlList)`
  .dhl-list {
    gap: 0;
    padding-inline-start: 15px;
  }
`;

const ProductInfoListItem = styled(DhlListItem)``;

const ProductInfoListText = styled(DhlText)`
  & span {
    font-size: 1.3rem;
  }
`;

const Small = styled.small`
  display: block;
  font-size: 1em;
  font-weight: 400;

  :before {
    content: "(";
  }

  :after {
    content: ")";
  }
`;
// #endregion

const getHeadline = (label, options) =>
  options.length !== 1 ? (
    label
  ) : (
    <Fragment>
      {label}
      <Small>
        <FormattedMessage
          id={`FS2.CardShipmentScale.shipmentScale_${options[0]}_Only`}
        />
      </Small>
    </Fragment>
  );

const triggerClickTrackingEvent = (product, name) => {
  trackContentInteraction({
    context: product,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: UTF_EVENT_POSITIONS.PRODUCT,
  });
};

const ShipmentProductCard = ({
  ariaDescribedBy,
  checkboxName,
  checkboxValue,
  describedByMessage,
  feedback,
  isChecked,
  isRequired,
  name,
  onSetIsChecked,
  product,
  productIcon,
  productInfo,
  productLabel,
  regions,
  trackingPath,
}) => {
  const onChangeCheckbox = (event) => {
    triggerClickTrackingEvent(
      checkboxValue,
      event.target.checked
        ? UTF_EVENT_NAMES.PRODUCT_CARD_ACTIVATION
        : UTF_EVENT_NAMES.PRODUCT_CARD_DEACTIVATION
    );
    onSetIsChecked([checkboxValue, event.target.checked]);
  };

  return (
    <CardSelectable
      ariaDescribedBy={`ShipmentProductCardInstructions-${name} ${ariaDescribedBy}`}
      ariaLabel={productLabel}
      dataTestid={`${checkboxName}-${checkboxValue}`}
      dataTrackingKeyPath={`${trackingPath}.checked`}
      feedback={feedback}
      isChecked={isChecked}
      isRequired={isRequired}
      name={checkboxName}
      onChange={onChangeCheckbox}
      value={checkboxValue}
    >
      <VisuallyHidden id={`ShipmentProductCardInstructions-${name}`}>
        {describedByMessage}
      </VisuallyHidden>

      <HeadlineContainer>
        <ProductIcon as={productIcon} />
        <ProductText>
          <ProductLabel
            aria-hidden="true"
            dataTestid={`${product}-product-label`}
          >
            {getHeadline(productLabel, regions)}
          </ProductLabel>
          <ProductInfo>
            <ProductInfoList>
              {productInfo.map((i, index) => (
                <ProductInfoListItem key={i}>
                  <ProductInfoListText
                    dataTestid={`${product}-product-info-${index}`}
                  >
                    {i}
                  </ProductInfoListText>
                </ProductInfoListItem>
              ))}
            </ProductInfoList>
          </ProductInfo>
        </ProductText>
      </HeadlineContainer>
    </CardSelectable>
  );
};

ShipmentProductCard.propTypes = {
  ariaDescribedBy: PropTypes.string,
  checkboxName: PropTypes.string.isRequired,
  checkboxValue: PropTypes.string.isRequired,
  describedByMessage: PropTypes.string,
  feedback: PropTypes.object,
  isChecked: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onSetIsChecked: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  productIcon: PropTypes.elementType.isRequired,
  productInfo: PropTypes.array.isRequired,
  productLabel: PropTypes.string.isRequired,
  regions: PropTypes.array.isRequired,
  trackingPath: PropTypes.string.isRequired,
};

ShipmentProductCard.defaultProps = {
  ariaDescribedBy: "",
  describedByMessage: "",
  feedback: {},
  isChecked: false,
  isRequired: false,
};

export default ShipmentProductCard;
