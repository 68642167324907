import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { identity, always } from "ramda";
import Highlight from "./Highlight";
import AutoCompleteField from "../AutoCompleteField";
import Flag from "../../Flag";
import FF, { Meta } from "../../../utils/functional/form-field";
import AutoComplete from "../../../utils/functional/auto-complete";
import { COUNTRY_KEY_NAMESPACE } from "../../../utils/constants";

const getValidation = (formField, feedbackMessageId, intl, label) => {
  if (FF.isValid(formField)) {
    return {
      type: "valid",
    };
  }

  return (
    feedbackMessageId && {
      type: "invalid",
      message: intl.formatMessage(
        { id: feedbackMessageId },
        { fieldName: label.toLowerCase() }
      ),
    }
  );
};

// #region Component Styles
const Container = styled.div`
  position: relative;
`;

const PlaceholderFlag = styled(Flag)`
  left: 13px;
  pointer-events: none;
  position: absolute;
  top: 13px;
  z-index: 1;
`;

const Option = styled.span`
  display: inline-flex;
  height: 32px;
  align-items: center;
`;

const StyledAutoComplete = styled(AutoCompleteField)`
  dhl-label label,
  input {
    padding-left: 55px;

    :focus {
      padding-left: 54px;
    }
  }
`;
// #endregion

const getCountryOptions = AutoComplete.case({
  unselected: (_, options) => options.slice(0, 10),
  _: always([]),
});

const getCountryValue = (intl, autoComplete) =>
  AutoComplete.case(
    {
      unselected: identity,
      selected: (value) =>
        intl.formatMessage({ id: `${COUNTRY_KEY_NAMESPACE}.${value}` }),
    },
    autoComplete
  );

const CountryAutoCompleteField = ({
  className,
  field,
  intl,
  label,
  queryCountry,
  placeholder,
  selectCountry,
  validateFormFields,
}) => {
  const countryField = FF.getValue(field);
  const value = getCountryValue(intl, countryField);
  const meta = FF.getMeta(field);
  const isRequired = Meta.isRequired(meta);
  const { feedbackMessageId } = FF.getFeedback(field) || {};

  const validation = getValidation(field, feedbackMessageId, intl, label);

  const variant = {
    label: isRequired ? `${label} *` : label,
    placeholder,
    type: "static",
  };

  return (
    <Container>
      <PlaceholderFlag $country={AutoComplete.getValue(countryField)} />

      <StyledAutoComplete
        ariaDescribe={intl.formatMessage({
          id: "FS2.AutocompleteField.screenReaderLabel_usageInstructions",
        })}
        ariaLabel={label}
        className={className}
        dataTestid={meta.id}
        id={meta.id}
        isRequired={isRequired}
        name={meta.id}
        numberOfOptionsCopy={intl.formatMessage({
          id: "FS2.AutocompleteField.screenReaderLabel_numberOfResults",
        })}
        onBlur={() => {
          validateFormFields(meta.id);
        }}
        onChange={(e) => queryCountry(e.target.value)}
        onOptionSelected={({ id }) => selectCountry(id)}
        options={getCountryOptions(countryField)}
        renderOption={(option) => (
          <Option>
            <Flag $country={option.id} />
            <Highlight content={option.value} term={value} />
          </Option>
        )}
        validation={validation}
        value={value}
        variant={variant}
      />
    </Container>
  );
};

CountryAutoCompleteField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  queryCountry: PropTypes.func.isRequired,
  selectCountry: PropTypes.func.isRequired,
  validateFormFields: PropTypes.func.isRequired,
};

export default CountryAutoCompleteField;
