// 9fbef606107a605d69c0edbcd8029e5d

import { memo } from "react";
import { compose } from "ramda";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as reducers from "../../../reducers";
import CountryCallingCodeField from ".";

const mapStateToProps = (state) => ({
  orderedCountryCallingCodesList: reducers.getOrderedCountryCallingCodesList(
    state
  ),
});

export default compose(
  memo,
  connect(mapStateToProps, null),
  injectIntl
)(CountryCallingCodeField);
