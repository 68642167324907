// 9fbef606107a605d69c0edbcd8029e5d
import { compose, find, propOr, propEq } from "ramda";
import { selectCountryEffect } from "../../../lead/contact-information/actions";
import { initSchedulerFromProfileEffect } from "../../../lead/scheduler/actions";
import {
  LOAD_CONFIGURATION,
  LOAD_CONFIGURATION_FAILURE,
  LOAD_CONFIGURATION_SUCCESS,
} from "../../../../actions";
import { DEFAULT_PROFILE } from "../../../../utils/constants";

/**
 * In case there are alternative versions of settings in the optional
 * qualifier array check for a matching entry, otherwise take the default
 * version in shipmentProducts which should always be present
 */
const selectSettings = (settings, qualifier) =>
  compose(
    propOr(settings?.shipmentProducts, "shipmentProducts"),
    find(propEq("name", qualifier)),
    propOr([], "qualifier")
  )(settings);

const loadConfiguration = (country) => ({
  payload: country,
  type: LOAD_CONFIGURATION,
});

const loadConfigurationSuccess = (
  country,
  configuration,
  settings,
  profile
) => ({
  payload: { country, configuration, settings, profile },
  type: LOAD_CONFIGURATION_SUCCESS,
});

const loadConfigurationFailure = (country, error) => ({
  error: true,
  payload: { country, error },
  type: LOAD_CONFIGURATION_FAILURE,
});

export const loadConfigurationEffect = (
  country,
  profile,
  integration,
  qualifier
) => (dispatch, _, { API }) => {
  dispatch(loadConfiguration(country));

  Promise.all([
    API.loadConfiguration(country, profile, integration),
    API.loadSettings(country, qualifier),
  ])
    .then(([configuration, settings]) => {
      dispatch(
        loadConfigurationSuccess(
          country,
          configuration,
          selectSettings(settings, qualifier),
          profile
        )
      );
      dispatch(selectCountryEffect(country));
      if (profile && profile !== DEFAULT_PROFILE) {
        dispatch(initSchedulerFromProfileEffect(profile));
      }
    })
    .catch((error) => dispatch(loadConfigurationFailure(country, error)));
};
