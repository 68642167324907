import React, { useState } from "react";
import PropTypes from "prop-types";
import { DhlTextAreaField } from "@dhl-official/react-library";
import styled from "styled-components";
import FF from "../../../utils/functional/form-field";

// #region Component Styles
/*
 * @TODO - Waiting for DUIL Feature Update!
 * Currently the DUIL DhlTextAreaField does not correctly place the validation icon without message
 * within the component (like DhlInput). Instead it's placed below the component, while waiting
 * for the DUIL fix we'll customize validation icon placing.
 */
const StyledTextAreaField = styled(DhlTextAreaField)`
  & dhl-validation-feedback {
    position: absolute;
    top: var(--dui-size-space-3x);
    right: var(--dui-size-space-2x);
  }
`;

const getValidation = (formField, feedbackMessageId, intl, label) => {
  if (FF.isValid(formField)) {
    return {
      type: "valid",
    };
  }

  return (
    feedbackMessageId && {
      type: "invalid",
      message: intl.formatMessage(
        { id: feedbackMessageId },
        { fieldName: label.toLowerCase() }
      ),
    }
  );
};

const CustomTextAreaField = ({
  className,
  field,
  intl,
  label,
  onChange,
  placeholder,
  validateFormFields,
}) => {
  const [showValidValidation, setShowValidValidation] = useState(false);

  const value = FF.getValue(field);
  const { id } = FF.getMeta(field);
  const { feedbackMessageId } = FF.getFeedback(field) || {};
  const validation =
    showValidValidation || !FF.isValid(field)
      ? getValidation(field, feedbackMessageId, intl, label)
      : undefined;

  const variant = {
    label,
    placeholder,
    type: "static",
  };

  return (
    <StyledTextAreaField
      dataAriaLabel={label}
      className={className}
      dataTestid={id}
      dataId={id}
      isRequired
      name={id}
      onDhlBlur={() => {
        validateFormFields(id);
        setShowValidValidation(true);
      }}
      onDhlInput={onChange}
      validation={validation}
      value={value}
      variant={variant}
    />
  );
};

CustomTextAreaField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  validateFormFields: PropTypes.func.isRequired,
};

export default CustomTextAreaField;
