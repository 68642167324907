/* eslint-disable no-loop-func */

import React, { Fragment, Suspense, useEffect } from "react";
import { always } from "ramda";
import { PropTypes } from "prop-types";
import { IntlProvider } from "react-intl";
import RD from "../utils/functional/remote-data";
import Application from "./Application";
import LoadingSkeleton from "./LoadingSkeleton/container";
import Tracking from "./Tracking/container";
import { PROFILES } from "../utils/constants";

const ErrorPage = React.lazy(() =>
  import(/* webpackChunkName: "bundle-secondary" */ "./ErrorPage")
);

const caseAllSuccessOrAnyOther = (caseObj, allRemoteDatas) => {
  const { error, loading, notAsked, success, _ = Function.prototype } = caseObj;
  let hasNotAsked = false;
  let hasError = false;
  let hasLoading = false;
  let successParams = [];

  for (let i = 0; i < allRemoteDatas.length; i += 1) {
    RD.case(
      {
        notAsked: () => {
          hasNotAsked = true;
        },
        error: () => {
          hasError = true;
        },
        loading: () => {
          hasLoading = true;
        },
        success: (data) => {
          successParams = [...successParams, data];
        },
      },
      allRemoteDatas[i]
    );
  }

  if (notAsked && hasNotAsked) {
    return notAsked();
  }

  if (error && hasError) {
    return error();
  }

  if (loading && hasLoading) {
    return loading();
  }

  if (success && successParams.length === allRemoteDatas.length) {
    return success(successParams);
  }

  return _();
};

const Entry = ({
  integration,
  loadConfiguration,
  loadTranslation,
  locale,
  pageNameLegacy,
  profile,
  qualifier,
  remoteConfiguration,
  remoteTranslations,
  scrollOffset,
}) => {
  const loadAppData = () => {
    const [, country] = locale.split("-");
    loadTranslation(locale);
    loadConfiguration(country, profile, integration, qualifier);
  };

  useEffect(loadAppData, [locale]);

  return (
    <Fragment>
      <Tracking />

      {caseAllSuccessOrAnyOther(
        {
          success: ([translations, configuration]) => (
            <IntlProvider
              locale={locale}
              messages={translations}
              wrapRichTextChunksInFragment
            >
              <Application
                canShowConnectors={Boolean(
                  configuration?.featureToggles?.showConnectors
                )}
                canShowCallbackRemoteConnector={Boolean(
                  configuration?.featureToggles?.showConnectors
                )}
                scrollOffset={scrollOffset}
              />
            </IntlProvider>
          ),
          loading: always(<LoadingSkeleton />),
          error: always(
            <Suspense fallback="">
              <ErrorPage
                appData={{
                  configuration: remoteConfiguration,
                  translations: remoteTranslations,
                }}
                loadAppData={loadAppData}
                locale={locale}
                pageNameLegacy={pageNameLegacy}
              />
            </Suspense>
          ),
          _: always(null),
        },
        [remoteTranslations, remoteConfiguration]
      )}
    </Fragment>
  );
};

Entry.propTypes = {
  integration: PropTypes.string,
  loadConfiguration: PropTypes.func.isRequired,
  loadTranslation: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  pageNameLegacy: PropTypes.string,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  qualifier: PropTypes.string,
  remoteTranslations: PropTypes.object.isRequired,
  remoteConfiguration: PropTypes.object.isRequired,
  scrollOffset: PropTypes.string,
};

export default Entry;
