import React from "react";
import { DhlDropdown, DhlText } from "@dhl-official/react-library";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { differenceInDays } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { compose, filter, map, pluck, propEq, uniq } from "ramda";
import { formatDateSlot } from "../../../utils";
import { getIANATimeZoneName } from "../../../utils/time-zone";
import { STYLES_VIEWPORTS } from "../../../utils/constants";

// #region Component Styles
const Container = styled.div`
  align-items: flex-start;
  border-top: solid 1px var(--dui-color-gray-300);
  display: flex;
  flex-direction: column;
  margin-top: var(--dui-size-space-7x);
  padding-bottom: var(--dui-size-space-7x);
  padding-top: calc(var(--dui-size-space-7x) * 2);

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const SchedulerNotice = styled(DhlText)``;

const SchedulerTitle = styled(DhlText).attrs({
  isParagraph: true,
  weight: 700,
})`
  & p {
    margin: 0;
  }
`;

const SchedulerTimes = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: var(--dui-size-space-10x);
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row;
    margin-top: 0;
    padding-left: calc(var(--dui-size-space-10x) / 2);
    width: 50%;
  }
`;

const SchedulerDay = styled(DhlDropdown)`
  width: 100%;
`;

const SchedulerSlot = styled(SchedulerDay)`
  & .dropdown-container {
    margin-top: var(--dui-size-space-7x);

    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      margin-left: var(--dui-size-space-7x);
      margin-top: 0;
    }
  }
`;
// #endregion

const Scheduler = ({
  intl,
  isRtlLayout,
  options,
  selectedDay,
  selectedSlot,
  setSchedulerDay,
  setSchedulerSlot,
  timeZone,
}) => {
  const daysList = compose(uniq, pluck("slotDayUserTimestamp"))(options);

  const onDayChange = (event) => {
    setSchedulerDay(event.target.value);
  };

  const onSlotChange = (event) => {
    setSchedulerSlot(event.target.value);
  };

  const now = utcToZonedTime(new Date(), timeZone);
  now.setHours(0, 0, 0, 0);

  const isSameTimeZone =
    formatInTimeZone(now, timeZone, "xx") ===
    formatInTimeZone(now, getIANATimeZoneName(), "xx");

  const schedulerDayOptions = daysList.map((entry) => {
    const date = new Date(parseInt(entry, 10));
    const daysDifference = differenceInDays(date, now);

    const label =
      daysDifference <= 1 && isSameTimeZone
        ? intl.formatMessage({
            id:
              daysDifference === 0
                ? "FS2.ContactForm.scheduler_today"
                : "FS2.ContactForm.scheduler_tomorrow",
          })
        : intl.formatDate(date, {
            day: "2-digit",
            month: "long",
            weekday: "short",
          });

    return { label, value: entry };
  });

  const schedulerSlotOptions = compose(
    map((option) => {
      return {
        label: formatDateSlot(
          [option.slotStartTimeUser, option.slotEndTimeUser],
          intl,
          isRtlLayout
        ),
        value: option.slotStartTimeMarketTimestamp,
      };
    }),
    filter(propEq("slotDayUserTimestamp", selectedDay))
  )(options);

  return (
    <Container>
      <SchedulerNotice>
        <SchedulerTitle>
          <FormattedMessage id="FS2.ContactForm.schedulerNotice" />
        </SchedulerTitle>
        <span data-testid="connectors-scheduler-timezone-indicator">
          {`(${formatInTimeZone(
            new Date(),
            getIANATimeZoneName(),
            "zzz"
          )} / ${getIANATimeZoneName()} )`}
        </span>
      </SchedulerNotice>
      <SchedulerTimes>
        <SchedulerDay
          onDhlChange={onDayChange}
          data={schedulerDayOptions}
          dataAriaLabel={intl.formatMessage({
            id: "FS2.ContactForm.formLabelSchedulerDay",
          })}
          dataId="select-day"
          dataTestid="select-day"
          name="select-day"
          value={selectedDay}
        />

        <SchedulerSlot
          onDhlChange={onSlotChange}
          data={schedulerSlotOptions}
          dataAriaLabel={intl.formatMessage({
            id: "FS2.ContactForm.formLabelSchedulerSlot",
          })}
          dataId="select-slot"
          dataTestid="select-slot"
          name="select-slot"
          value={selectedSlot.slotStartTimeMarketTimestamp}
        />
      </SchedulerTimes>
    </Container>
  );
};

Scheduler.propTypes = {
  intl: PropTypes.object.isRequired,
  isRtlLayout: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  selectedDay: PropTypes.string.isRequired,
  selectedSlot: PropTypes.object.isRequired,
  setSchedulerDay: PropTypes.func.isRequired,
  setSchedulerSlot: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
};

export default Scheduler;
