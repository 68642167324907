// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Scheduler from ".";
import * as actions from "../../../reducers/actions";
import * as reducers from "../../../reducers";

const mapStateToProps = (state, { businessUnit }) => {
  const { day, options, slot } = reducers.getScheduler(state);

  return {
    isRtlLayout: reducers.isRtlLayout(state),
    options: options[businessUnit],
    selectedDay: day,
    selectedSlot: slot,
    timeZone: reducers.getConnectorsTimeZone(state),
  };
};

const mapDispatchToProps = (dispatch, { businessUnit }) => ({
  setSchedulerDay: (day) =>
    dispatch(actions.setSchedulerDay(day, businessUnit)),
  setSchedulerSlot: (slot) =>
    dispatch(actions.setSchedulerSlot(slot, businessUnit)),
});

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(Scheduler);
