// 9fbef606107a605d69c0edbcd8029e5d

import { combineReducers } from "redux";
import translationsReducer, * as translations from "./translations";
import configurationsReducer, * as configurations from "./configurations";
import localeReducer, * as locale from "./locale";

export default combineReducers({
  translations: translationsReducer,
  configurations: configurationsReducer,
  locale: localeReducer,
});

export const getTranslation = (state) => translations.get(state.translations);

export const getConfiguration = (state) =>
  configurations.get(state.configurations);

export const getLocale = (state) => locale.get(state.locale);
