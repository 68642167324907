import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { DhlLink, DhlText } from "@dhl-official/react-library";
import {
  Time as IconTime,
  IlluFreightShipping,
  IlluParcelDocuments,
  IlluTwoDocuments,
} from "@dhl-official/icons";
import { compose, prop } from "ramda";
import FF from "../../../utils/functional/form-field";
import Card from "./Card/container";
import CardSelectableGroup from "../../Cards/Group";
import { trackContentInteraction } from "../../../utils/track-utf";
import { createAbsoluteUrl } from "../../../utils/absolute-url";
import { getLinkTarget } from "../../../utils/link-target";
import {
  STEPS,
  STYLES_BASE_FONT_SIZE,
  STYLES_CUSTOM_BREAKPOINT_CARDS,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
} from "../../../utils/constants";

// #region Component Styles
const Container = styled.div``;

const CardContainer = styled.li`
  flex-direction: row;
  list-style: none;
  margin: var(--dui-size-space-3x);
  width: calc(${STYLES_BASE_FONT_SIZE} * 2.4rem);

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    width: calc(${STYLES_BASE_FONT_SIZE} * 2.9rem);
  }
`;

const ReroutingNotice = styled(DhlText).attrs({
  isParagraph: true,
  size: "sm",
})`
  p {
    margin: var(--dui-size-space-7x) auto var(--dui-size-space-10x) auto;
    max-width: 600px;
    text-align: center;
  }
`;

const AlternativeServiceLink = styled(DhlLink).attrs({
  fontSize: "sm",
  rel: "noopener noreferrer",
})``;

const TimeIndicator = styled(DhlText).attrs({
  isParagraph: true,
  size: "sm",
})`
  & p {
    color: var(--dui-color-gray-600);
    margin-top: var(--dui-size-space-7x);
    text-align: center;
  }
`;

const StyledIconTime = styled(IconTime)`
  box-sizing: content-box;
  color: var(--dui-color-gray-500);
  height: 16px;
  padding-right: var(--dui-size-space-3x);
  position: relative;
  top: 4px;
  width: 16px;
`;
// #endregion

const CHECKBOX_GROUP_NAME = "shipmentProduct";

const TRACKING_KEYS = {
  reroutingSingleProduct:
    "regularShipmentLP.interactions.rerouting.reroutingSingleProductLinkClicked",
  reroutingSingleRegion:
    "regularShipmentLP.interactions.rerouting.reroutingSingleRegionLinkClicked",
  shipmentProduct:
    "digitalLayer.gaq.regularShipmentLP.requestData.shipmentProduct",
};

const FREIGHT = "FREIGHT";
const MAIL = "MAIL";
const PARCEL = "PARCEL";

const SHIPMENT_PRODUCT_ICONS = {
  [FREIGHT]: IlluFreightShipping,
  [MAIL]: IlluTwoDocuments,
  [PARCEL]: IlluParcelDocuments,
};

const REROUTING_DATA_TESTIDS = {
  product: {
    link: "rerouting-product-single-product-link",
    notice: "rerouting-product-single-product-notice",
  },
  region: {
    link: "rerouting-product-single-region-link",
    notice: "rerouting-product-single-region-notice",
  },
};

const getRegions = compose(Object.keys, FF.getValue, prop("regions"));

const triggerLinkTrackingEvent = (context, name, position) => {
  trackContentInteraction({
    context,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position,
    attributes: {
      step: UTF_EVENT_POSITIONS.PRODUCT,
    },
  });
};

const Product = ({
  activeStep,
  fields,
  intl,
  openLinksInSameTab,
  options,
  singleProduct,
  singleProductRerouting,
  singleRegion,
  singleRegionRerouting,
}) => {
  useEffect(() => {
    if (singleRegionRerouting) {
      triggerLinkTrackingEvent(
        singleRegionRerouting,
        UTF_EVENT_NAMES.SINGLE_REGION,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }
    if (singleProductRerouting) {
      triggerLinkTrackingEvent(
        singleProductRerouting,
        UTF_EVENT_NAMES.SINGLE_PRODUCT,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }
  }, [singleRegionRerouting, singleProductRerouting]);

  const getMessage = (id) => intl.formatMessage({ id });

  const describedByMessage = getMessage(
    "FS2.CardShipmentProduct.screenReaderLabel_shipmentProductDescription"
  );

  return (
    <Container>
      <CardSelectableGroup
        activeStep={activeStep}
        feedback={FF.getFeedback(fields)}
        idForLabeledBy={`${CHECKBOX_GROUP_NAME}-labelId`}
        legendCopy={getMessage("FS2.RegularShipment.step1Headline")}
      >
        {(ariaDescribedBy) =>
          options.map((product) => {
            const name = `${CHECKBOX_GROUP_NAME}_${product}`;
            const formField = FF.getValue(fields)[product];
            const regions = getRegions(formField);

            const productLabel = getMessage(
              `FS2.CardShipmentProduct.shipmentProduct_${product}_Label`
            );

            const productInfo = intl
              .formatMessage({ id: `FS2.Product_${product}_Instructions` })
              .split("|||");

            return (
              <CardContainer key={name}>
                <Card
                  ariaDescribedBy={ariaDescribedBy}
                  checkboxName={CHECKBOX_GROUP_NAME}
                  checkboxValue={product}
                  describedByMessage={describedByMessage}
                  feedback={FF.getFeedback(fields)}
                  frequency={formField.frequency}
                  intl={intl}
                  isChecked={formField.checked}
                  isRequired
                  name={name}
                  numberOfShipments={formField.numberOfShipments}
                  product={product}
                  productIcon={SHIPMENT_PRODUCT_ICONS[product]}
                  productInfo={productInfo}
                  productLabel={productLabel}
                  regions={regions}
                  trackingPath={`${
                    TRACKING_KEYS.shipmentProduct
                  }.${product.toLowerCase()}`}
                />
              </CardContainer>
            );
          })
        }
      </CardSelectableGroup>

      {singleRegion && (
        <ReroutingNotice dataTestid={REROUTING_DATA_TESTIDS.region.notice}>
          {singleRegionRerouting ? (
            <FormattedMessage
              id={`FS2.Rerouting.RegionProductWithLink_${singleRegion}`}
              values={{
                alternativeService: (
                  <AlternativeServiceLink
                    dataTestid={REROUTING_DATA_TESTIDS.region.link}
                    dataTracking={`${TRACKING_KEYS.reroutingSingleRegion}.${singleRegionRerouting}`}
                    href={createAbsoluteUrl(
                      getMessage(
                        `FS2.Rerouting.AlternativeService_${singleRegionRerouting}_URL`
                      )
                    )}
                    onClick={() =>
                      triggerLinkTrackingEvent(
                        singleRegionRerouting,
                        UTF_EVENT_NAMES.SINGLE_REGION,
                        UTF_EVENT_POSITIONS.REROUTING_CLICK
                      )
                    }
                    target={getLinkTarget(openLinksInSameTab)}
                  >
                    <FormattedMessage
                      id={`FS2.Rerouting.AlternativeService_${singleRegionRerouting}_Label`}
                    />
                  </AlternativeServiceLink>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id={`FS2.Rerouting.RegionProductWithoutLink_${singleRegion}`}
            />
          )}
        </ReroutingNotice>
      )}

      {singleProduct && (
        <ReroutingNotice dataTestid={REROUTING_DATA_TESTIDS.product.notice}>
          {singleProductRerouting ? (
            <FormattedMessage
              id={`FS2.Rerouting.SingleProductWithLink_${singleProduct}`}
              values={{
                alternativeService: (
                  <AlternativeServiceLink
                    dataTestid={REROUTING_DATA_TESTIDS.product.link}
                    dataTracking={`${TRACKING_KEYS.reroutingSingleProduct}.${singleProductRerouting}`}
                    href={createAbsoluteUrl(
                      getMessage(
                        `FS2.Rerouting.AlternativeService_${singleProductRerouting}_URL`
                      )
                    )}
                    onClick={() =>
                      triggerLinkTrackingEvent(
                        singleProductRerouting,
                        UTF_EVENT_NAMES.SINGLE_PRODUCT,
                        UTF_EVENT_POSITIONS.REROUTING_CLICK
                      )
                    }
                    target={getLinkTarget(openLinksInSameTab)}
                  >
                    <FormattedMessage
                      id={`FS2.Rerouting.AlternativeService_${singleProductRerouting}_Label`}
                    />
                  </AlternativeServiceLink>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id={`FS2.Rerouting.SingleProductWithoutLink_${singleProduct}`}
            />
          )}
        </ReroutingNotice>
      )}

      <TimeIndicator dataTestid="time-indicator-text">
        <StyledIconTime />
        <FormattedMessage id="FS2.MultiPageForm.timeIndicator" />
      </TimeIndicator>
    </Container>
  );
};

Product.propTypes = {
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  fields: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  openLinksInSameTab: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  singleProduct: PropTypes.string,
  singleProductRerouting: PropTypes.string,
  singleRegion: PropTypes.string,
  singleRegionRerouting: PropTypes.string,
};

export default Product;
