// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  LOAD_PHONE_NUMBER_VALIDATION_LIB,
  LOAD_PHONE_NUMBER_VALIDATION_LIB_FAILURE,
  LOAD_PHONE_NUMBER_VALIDATION_LIB_SUCCESS,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_PHONE_NUMBER_VALIDATION_LIB:
      return RD.loading();

    case LOAD_PHONE_NUMBER_VALIDATION_LIB_SUCCESS:
      return RD.success(payload);

    case LOAD_PHONE_NUMBER_VALIDATION_LIB_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
