// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import { SET_AB_TESTING } from "../../../actions";

const reducer = (abTesting) => (state = abTesting, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_AB_TESTING: {
      return payload;
    }
    default:
      return state;
  }
};

export const get = identity;

export default reducer;
