import PropTypes from "prop-types";
import {
  DhlCheckbox,
  DhlText,
  DhlValidationFeedback,
} from "@dhl-official/react-library";
import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { compose, prop, always } from "ramda";
import FF from "../../../../utils/functional/form-field";

// #region Component Styles
const Container = styled.div`
  margin: var(--dui-size-space-10x) 0;
`;

const List = styled.ul.attrs({
  "data-testid": "parcel-urgency-options",
})`
  line-height: initial;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
`;

const Info = styled(DhlText).attrs({ isParagraph: true, size: "sm" })`
  & p {
    margin-bottom: var(--dui-size-space-7x);
  }
`;

const Item = styled.li`
  margin-bottom: var(--dui-size-space-7x);
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Option = styled(DhlCheckbox).attrs({
  size: "md",
})`
  & > div > label.md {
    height: auto;
  }
`;

const OptionContent = styled.div``;

const Title = styled(DhlText).attrs({
  isParagraph: true,
  weight: 700,
})`
  & p {
    margin: 0;
  }
`;

const SubTitle = styled(Title).attrs({ size: "sm", weight: 400 })`
  & p {
    color: var(--dui-color-gray-600);
    margin: 0;
  }
`;
// #endregion

const RegionOptions = ({
  className,
  intl,
  isInvalid,
  options,
  onChange,
  product,
  regions,
  selectedRegions,
  trackingPath,
}) => (
  <Container>
    <Info dataTestid={`${product}-shipmentScaleInfo-heading`}>
      <FormattedMessage id="FS2.CardShipmentScale.shipmenScaleInfo" />
    </Info>
    <List className={className}>
      {options.map((region) => (
        <Item
          key={region}
          data-testid={`shipmentProduct_${product}_region_${region}_listItem`}
        >
          <Option
            dataTestid={`shipmentProduct_${product}_region_${region}`}
            dataTracking={`${trackingPath}.shipmentScales`}
            checked={selectedRegions.includes(region)}
            name={region}
            onDhlChange={() => onChange(region)}
            value={region}
            validation={FF.case(
              {
                invalid: () => ({
                  type: "invalid",
                  message: "",
                }),
                _: always(undefined),
              },
              regions
            )}
          >
            <OptionContent>
              <Title dataTestid={`shipmentProduct-${product}-${region}-label`}>
                <FormattedMessage
                  id={`FS2.CardShipmentScale.shipmentScale_${region}`}
                />
              </Title>
              <SubTitle
                dataTestid={`shipmentProduct-${product}-${region}-explanation`}
              >
                {`(${intl.formatMessage({
                  id: `FS2.CardShipmentScale.shipmentScale_${region}_Explanation`,
                })})`}
              </SubTitle>
            </OptionContent>
          </Option>
        </Item>
      ))}
    </List>
    {isInvalid && (
      <DhlValidationFeedback
        dataTestid={`shipmentProduct-${product}-region-validationMessage`}
        validation={{
          type: "invalid",
          message: intl.formatMessage({
            id: `${compose(
              prop("feedbackMessageId"),
              FF.getFeedback
            )(regions)}`,
          }),
        }}
      />
    )}
  </Container>
);

RegionOptions.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  product: PropTypes.string.isRequired,
  regions: PropTypes.object,
  selectedRegions: PropTypes.array,
  trackingPath: PropTypes.string.isRequired,
};

RegionOptions.defaultProps = {
  selectedRegions: [],
};

export default RegionOptions;
