// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import * as reducers from "../reducers";
import * as actions from "../reducers/actions";
import Entry from ".";

const mapStateToProps = (state) => ({
  integration: reducers.getIntegration(state),
  pageNameLegacy: reducers.getPageNameLegacy(state),
  profile: reducers.getProfile(state),
  qualifier: reducers.getQualifier(state),
  remoteConfiguration: reducers.getConfiguration(state),
  remoteTranslations: reducers.getTranslation(state),
  scrollOffset: reducers.getScrollOffset(state),
});

const mapDispatchToProps = {
  loadConfiguration: actions.loadConfigurationEffect,
  loadTranslation: actions.loadTranslationEffect,
};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps)
)(Entry);
