import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  IlluFreightShipping,
  IlluParcelDocuments,
  IlluTwoDocuments,
} from "@dhl-official/icons";
import { compose, map, path, filter } from "ramda";
import FF from "../../../utils/functional/form-field";
import Card from "./Card/container";
import SingleCard from "./SingleCard/container";
import ParcelOptions from "./ParcelOptions/container";
import CardGroup from "../../Cards/Group";
import {
  FREQUENCY_OPTIONS,
  PROFILES,
  STEPS,
  STYLES_BASE_FONT_SIZE,
  STYLES_CUSTOM_BREAKPOINT_CARDS,
  PARCEL_OPTIONS,
} from "../../../utils/constants";

// #region Component Styles
const CardContainer = styled.li`
  flex-direction: row;
  list-style: none;
  margin: var(--dui-size-space-3x);

  width: calc(${STYLES_BASE_FONT_SIZE} * 2.4rem);

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    width: calc(${STYLES_BASE_FONT_SIZE} * 2.9rem);
  }
`;
// #endregion

const CHECKBOX_GROUP_NAME = "productFields";
const TRACKING_KEY =
  "digitalLayer.gaq.regularShipmentLP.requestData.shipmentProduct";

const FREIGHT = "FREIGHT";
const MAIL = "MAIL";
const PARCEL = "PARCEL";

const SHIPMENT_PRODUCT_ICONS = {
  [FREIGHT]: IlluFreightShipping,
  [MAIL]: IlluTwoDocuments,
  [PARCEL]: IlluParcelDocuments,
};

const getExtraOptionsComponent = (id, formField) => {
  if (!formField.options) {
    return undefined;
  }

  switch (id) {
    case PARCEL:
      return <ParcelOptions selectedOptions={formField.options} />;

    default:
      return undefined;
  }
};

const getParcelOption = (options) => {
  if (options?.standard && !options?.expedited) {
    return PARCEL_OPTIONS.STANDARD;
  }
  if (!options?.standard && options?.expedited) {
    return PARCEL_OPTIONS.EXPEDITED;
  }
  return undefined;
};

const getFrequencyOptions = (intl, frequencies) =>
  frequencies.map((option) => ({
    ...option,
    label: intl.formatMessage({
      id: `FS2.CardShipmentProduct.frequencyOptionLabel_${option.key}`,
    }),
  }));

const getShipmentScaleOptions = (product) =>
  compose(FF.getValue, path([product, "regions"]), FF.getValue);

const getRegionOptions = (productFields) => (product) =>
  compose(Object.keys, getShipmentScaleOptions(product))(productFields);

const getSelectedRegions = (productFields) => (product) =>
  compose(
    map(([region]) => region),
    filter(([, checked]) => checked),
    Object.entries,
    getShipmentScaleOptions(product)
  )(productFields);

const getProductRegionValidation = (product) =>
  compose(FF.isInvalid, path([product, "regions"]), FF.getValue);

const getRegionValidation = (productFields) => (product) =>
  compose(getProductRegionValidation(product))(productFields);

const ProductDetails = ({
  activeStep,
  contentPrefix,
  freightNoMatches,
  intl,
  mailNoMatches,
  options,
  parcelNoMatches,
  productFields,
  profile,
  shouldShowOptions,
}) => {
  const getMessage = (id) => intl.formatMessage({ id });

  const frequencyOptions = getFrequencyOptions(intl, FREQUENCY_OPTIONS);
  const getRegionOptionsForProduct = getRegionOptions(productFields);
  const getSelectedRegionsForProduct = getSelectedRegions(productFields);
  const getRegionValidationForProduct = getRegionValidation(productFields);

  const getNoMatches = (product) => {
    switch (product) {
      case "PARCEL":
        return parcelNoMatches;

      case "FREIGHT":
        return freightNoMatches;

      case "MAIL":
      default:
        return mailNoMatches;
    }
  };

  const getStepHeadlineId = () =>
    profile === PROFILES.FS
      ? "FS2.RegularShipment.step2Headline"
      : `${contentPrefix}.RegularShipment.stepProductDetailsHeadline`;

  return (
    <CardGroup
      activeStep={activeStep}
      feedback={FF.getFeedback(productFields)}
      idForLabeledBy={`${CHECKBOX_GROUP_NAME}-labelId`}
      legendCopy={getMessage(getStepHeadlineId())}
    >
      {() =>
        options
          .filter((product) => FF.getValue(productFields)[product].checked)
          .map((product, _, filteredOptions) => {
            const key = `${CHECKBOX_GROUP_NAME}_${product}`;
            const formField = FF.getValue(productFields)[product];
            const regionOptions = getRegionOptionsForProduct(product);
            const isRegionInvalid = getRegionValidationForProduct(product);
            const selectedRegions = getSelectedRegionsForProduct(product);

            const productLabel = getMessage(
              `FS2.CardShipmentProduct.shipmentProduct_${product}_Label`
            );

            const productInfo = intl
              .formatMessage({ id: `FS2.Product_${product}_Instructions` })
              .split("|||")
              .slice(0, 2);

            return (
              <CardContainer key={key}>
                {filteredOptions.length === 1 ? (
                  <SingleCard
                    extraOptions={
                      shouldShowOptions
                        ? getExtraOptionsComponent(product, formField)
                        : undefined
                    }
                    frequency={formField.frequency}
                    frequencyOptions={frequencyOptions}
                    name={CHECKBOX_GROUP_NAME}
                    noMatches={getNoMatches(product)}
                    numberOfShipments={formField.numberOfShipments}
                    parcelOption={getParcelOption(formField.options)}
                    product={product}
                    productIcon={SHIPMENT_PRODUCT_ICONS[product]}
                    productInfo={productInfo}
                    productLabel={productLabel}
                    profile={profile}
                    isRegionInvalid={isRegionInvalid}
                    regionOptions={regionOptions}
                    regions={formField.regions}
                    selectedRegions={selectedRegions}
                    trackingPath={`${TRACKING_KEY}.${product.toLowerCase()}`}
                  />
                ) : (
                  <Card
                    extraOptions={
                      shouldShowOptions
                        ? getExtraOptionsComponent(product, formField)
                        : undefined
                    }
                    frequency={formField.frequency}
                    frequencyOptions={frequencyOptions}
                    name={CHECKBOX_GROUP_NAME}
                    noMatches={getNoMatches(product)}
                    numberOfShipments={formField.numberOfShipments}
                    parcelOption={getParcelOption(formField.options)}
                    product={product}
                    productIcon={SHIPMENT_PRODUCT_ICONS[product]}
                    productInfo={productInfo}
                    productLabel={productLabel}
                    profile={profile}
                    isRegionInvalid={isRegionInvalid}
                    regionOptions={regionOptions}
                    regions={formField.regions}
                    selectedRegions={selectedRegions}
                    trackingPath={`${TRACKING_KEY}.${product.toLowerCase()}`}
                  />
                )}
              </CardContainer>
            );
          })
      }
    </CardGroup>
  );
};

ProductDetails.propTypes = {
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  contentPrefix: PropTypes.string.isRequired,
  freightNoMatches: PropTypes.object,
  intl: PropTypes.object.isRequired,
  mailNoMatches: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  parcelNoMatches: PropTypes.object,
  productFields: PropTypes.object.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  shouldShowOptions: PropTypes.bool.isRequired,
};

export default ProductDetails;
