// 9fbef606107a605d69c0edbcd8029e5d

import { prop, compose } from "ramda";
import { memo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ContactForm from ".";
import RD from "../../utils/functional/remote-data";
import * as reducers from "../../reducers";
import * as actions from "../../reducers/actions";

const mapStateToProps = (state) => {
  const featureFlags = reducers.getFeatureFlags(state);

  const getFlag = (flag) =>
    compose(RD.withDefault(false), RD.map(prop(flag)))(featureFlags);

  return {
    callbackRemoteDisplay: reducers.getCallbackRemoteDisplay(state),
    connectors: reducers.getConnectors(state),
    contactInformation: reducers.getContactInformation(state),
    openLinksInSameTab: reducers.getOpenLinksInSameTab(state),
    schedulerDisplay: reducers.getSchedulerDisplay(state),
    theme: reducers.getTheme(state),
    withPhoneExtension: getFlag("withPhoneExtension"),
  };
};

const mapDispatchToProps = {
  onContactInformationChange: actions.setContactInformation,
  onValidateContactInformation: actions.validateContactInformation,
  onValidateCountry: actions.validateCountryEffect,
};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ContactForm);
