// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ViewHeadline from ".";
import * as reducers from "../../reducers";

const mapStateToProps = (state) => ({
  theme: reducers.getTheme(state),
});

export default compose(
  memo,
  connect(mapStateToProps),
  injectIntl
)(ViewHeadline);
