// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import MultiStepForm from ".";
import * as reducers from "../../reducers";
import * as actions from "../../reducers/actions";

const mapStateToProps = (state) => ({
  activeStep: reducers.getActiveStep(state),
  activeStepNumber: reducers.getActiveStepNumber(state),
  hasUserInteraction: reducers.hasUserInteraction(state),
  contentPrefix: reducers.getContentPrefix(state),
  isRtlLayout: reducers.isRtlLayout(state),
  numberOfSteps: reducers.getNumberOfSteps(state),
  profile: reducers.getProfile(state),
  shouldHideScreenReaderAnnouncement: reducers.shouldHideScreenReaderAnnouncement(
    state
  ),
  shouldHideStepIndicator: reducers.shouldHideStepIndicator(state),
  stepsHistory: reducers.getStepsHistory(state),
  theme: reducers.getTheme(state),
});

const mapDispatchToProps = {
  goToNextStep: actions.goToNextStepEffect,
  goToPreviousStep: actions.goToPreviousStepEffect,
};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MultiStepForm);
