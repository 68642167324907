import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ApplicationHeadline from "../ApplicationHeadline/container";
import MultiStepForm from "../MultiStepForm/container";
import ModalError from "../Modals/Error/container";
import ModalErrorCallbackRemote from "../Modals/ErrorCallbackRemote/container";
import ModalConnectorSelector from "../Modals/ConnectorSelector/container";

// #region Component Styles
const Container = styled.div`
  background-color: var(--dui-color-white-500);
  font-size: var(--dui-size-space-7x);
  padding-top: var(--fs-application-container-padding-top);
  scroll-margin-top: ${({ $scrollOffset }) => $scrollOffset};
  width: 100%;
`;
// #endregion

const Application = ({
  canShowConnectors,
  canShowCallbackRemoteConnector,
  scrollOffset,
}) => {
  const applicationRef = useRef();

  return (
    <Container $scrollOffset={scrollOffset} ref={applicationRef}>
      <ApplicationHeadline />

      <MultiStepForm applicationRef={applicationRef} />

      {canShowConnectors && <ModalConnectorSelector />}
      {canShowCallbackRemoteConnector && <ModalErrorCallbackRemote />}

      <ModalError>
        <FormattedMessage id="FS2.ModalError.copy" />
      </ModalError>
    </Container>
  );
};

Application.propTypes = {
  canShowConnectors: PropTypes.bool.isRequired,
  canShowCallbackRemoteConnector: PropTypes.bool.isRequired,
  scrollOffset: PropTypes.string,
};

export default Application;
