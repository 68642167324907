import React, { memo } from "react";
import styled, { css } from "styled-components";
import { ChevronBack as IconChevronBack } from "@dhl-official/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { THEMES } from "../../../utils/constants";
import * as reducers from "../../../reducers";

export const PreviousButtonStyled = styled.button.attrs({
  type: "button",
})`
  color: var(--dui-color-black-400);
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  border: solid 2px transparent;
  display: flex;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  padding: 10px 30px 11px 30px;
  transition: border-color var(--dui-duration-default);

  &:focus-visible {
    border-color: var(--dui-color-black-400);
  }
`;

const getMyDHLPlusThemeStyles = () => css`
  color: var(--mydhlplus-color-blue-400);
`;

export const BackArrow = styled(IconChevronBack)`
  color: var(--dui-color-red-500);
  height: 20px;
  width: 20px;
  margin-right: 5px;
  transform: rotate(
    ${({ $isRtlLayout }) => ($isRtlLayout ? "180deg" : "0deg")}
  );

  ${({ $theme }) => $theme === THEMES.MYDHLPLUS && getMyDHLPlusThemeStyles()};
`;

const PreviousButton = ({
  dataTestid,
  dataTracking,
  name,
  onClick,
  isRtlLayout,
  theme,
}) => {
  return (
    <PreviousButtonStyled
      data-testid={dataTestid}
      data-tracking={dataTracking}
      name={name}
      onClick={onClick}
    >
      <BackArrow $isRtlLayout={isRtlLayout} $theme={theme} />
      <FormattedMessage id="FS2.MultiPageFormStep.buttonPrevious" />
    </PreviousButtonStyled>
  );
};

PreviousButton.propTypes = {
  // input
  onClick: PropTypes.func.isRequired,
  dataTestid: PropTypes.string.isRequired,
  dataTracking: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // from redux
  isRtlLayout: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

const mapStateToProps = (state) => ({
  activeStep: reducers.getActiveStep(state),
  contentPrefix: reducers.getContentPrefix(state),
  profile: reducers.getProfile(state),
  isNextStepEnabled: reducers.isNextStepEnabled(state),
  isRtlLayout: reducers.isRtlLayout(state),
  theme: reducers.getTheme(state),
  isWaitingForEndpoint: reducers.getIsLoadingGotLead(state),
});

export default memo(connect(mapStateToProps, {})(PreviousButton));
