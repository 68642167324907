import React, { Fragment, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { DhlButton, DhlHeadline, DhlText } from "@dhl-official/react-library";
import { Exception as IconException } from "@dhl-official/icons";
import Modal from "../../Modal";
import { STYLES_VIEWPORTS } from "../../../utils/constants";

// #region Component Styles
const StyledModal = styled(Modal)`
  &.dhl-cj-fs {
    direction: ltr;
    max-width: 100%;
    min-height: auto;
    max-height: none;
    width: 95vw;

    @media screen and (max-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      padding: 30px 20px;
    }

    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      max-width: 600px;
      padding: calc(var(--dui-size-space-3x) * 9)
        calc(var(--dui-size-space-3x) * 13);
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row;
  }
`;

const Warning = styled(IconException).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  color: var(--dui-color-red-500);
  height: 76px;
  margin-bottom: var(--dui-size-space-10x);
  vertical-align: middle;
  width: 76px;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    margin-bottom: 0;
    margin-left: calc(var(--dui-size-space-10x) * -2);
    margin-right: calc(var(--dui-size-space-10x) * 2);
    min-width: 76px;
  }
`;

const TextContent = styled.div``;

const Title = styled(DhlHeadline).attrs({
  designLevel: 3,
  dataTestid: "retry-modal-headline",
  tag: "h3",
})``;

const Subline = styled(DhlHeadline).attrs({
  designLevel: 4,
  tag: "h4",
  variant: "light",
})`
  & h4 {
    font-size: var(--dui-size-font-2xl);
  }
`;

const Description = styled(DhlText).attrs({
  isParagraph: true,
})`
  & p {
    margin-top: var(--dui-size-space-7x);
  }
`;

const ButtonsContainer = styled.div``;

const StyledButton = styled(DhlButton)`
  & button {
    margin-top: var(--dui-size-space-10x);
    margin-right: var(--dui-size-space-7x);
  }
`;
// #endregion

const ModalError = ({
  children,
  headline,
  intl,
  isOpen,
  isRetrying,
  onClose,
  onRetry,
  renderActionButtons,
  subline,
}) => (
  <StyledModal
    className="dhl-cj-fs"
    closeButtonAriaLabel={intl.formatMessage({ id: "FS2.ContentModal.close" })}
    contentAriaLabel={`${headline} ${subline}`}
    isOpen={isOpen}
    onClose={onClose}
    overlayColor="rgba(204, 204, 204, 0.9)"
    shouldShowCloseButton={false}
  >
    <Content>
      <Warning />

      <TextContent>
        <Title>{headline}</Title>
        <Subline>{subline}</Subline>
        <Description>{children}</Description>

        <ButtonsContainer>
          {renderActionButtons ? (
            renderActionButtons()
          ) : (
            <Fragment>
              <StyledButton
                clickEvent={onRetry}
                dataTestid="retry-modal-retry-button"
                isDisabled={isRetrying}
                variant="primary"
              >
                <FormattedMessage id="FS2.ModalError.retry" />
              </StyledButton>
              <StyledButton
                clickEvent={onClose}
                dataTestid="retry-modal-cancel-button"
                variant="outline"
              >
                <FormattedMessage id="FS2.ModalError.cancel" />
              </StyledButton>
            </Fragment>
          )}
        </ButtonsContainer>
      </TextContent>
    </Content>
  </StyledModal>
);

ModalError.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  isRetrying: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func,
  renderActionButtons: PropTypes.func,
  subline: PropTypes.string.isRequired,
};

ModalError.defaultProps = {
  isOpen: false,
  isRetrying: false,
  onRetry: Function.prototype,
};

export default memo(injectIntl(ModalError));
