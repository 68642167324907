// 9fbef606107a605d69c0edbcd8029e5d

import { propEq, prop, curry } from "ramda";

const REMOTE_DATA_TYPE = "RemoteData";

const REMOTE_DATA_TAG = {
  NOT_ASKED: "Not Asked",
  LOADING: "Loading",
  SUCCESS: "Success",
  ERROR: "Error",
};

const NOOP = Function.prototype;

const RemoteData = {
  chain: (chainFun, remoteData) =>
    remoteData.tag === REMOTE_DATA_TAG.SUCCESS
      ? chainFun(remoteData.data, remoteData.correlationId)
      : remoteData,

  error: (error, correlationId = undefined) => ({
    correlationId,
    error,
    tag: REMOTE_DATA_TAG.ERROR,
    type: REMOTE_DATA_TYPE,
  }),

  notAsked: () => ({
    tag: REMOTE_DATA_TAG.NOT_ASKED,
    type: REMOTE_DATA_TYPE,
  }),

  loading: (correlationId = undefined) => ({
    correlationId,
    tag: REMOTE_DATA_TAG.LOADING,
    type: REMOTE_DATA_TYPE,
  }),

  success: (data, correlationId = undefined) => ({
    correlationId,
    data,
    tag: REMOTE_DATA_TAG.SUCCESS,
    type: REMOTE_DATA_TYPE,
  }),

  case: curry((caseObj, remoteData) => {
    const { error, loading, notAsked, success, _ = NOOP } = caseObj;

    if (success && RemoteData.isSuccess(remoteData)) {
      return success(remoteData.data, remoteData.correlationId);
    }

    if (loading && RemoteData.isLoading(remoteData)) {
      return loading(remoteData.correlationId);
    }

    if (notAsked && RemoteData.isNotAsked(remoteData)) {
      return notAsked();
    }

    if (error && RemoteData.isError(remoteData)) {
      return error(remoteData.error, remoteData.correlationId);
    }

    return _();
  }),

  map: curry((mapFun, remoteData) =>
    remoteData.tag === REMOTE_DATA_TAG.SUCCESS
      ? RemoteData.success(mapFun(remoteData.data), remoteData.correlationId)
      : remoteData
  ),

  withDefault: curry((defaultValue, remoteData) =>
    remoteData.tag === REMOTE_DATA_TAG.SUCCESS ? remoteData.data : defaultValue
  ),

  getCorrelationId: prop("correlationId"),

  isNotAsked: propEq("tag", REMOTE_DATA_TAG.NOT_ASKED),

  isLoading: propEq("tag", REMOTE_DATA_TAG.LOADING),

  isSuccess: propEq("tag", REMOTE_DATA_TAG.SUCCESS),

  isError: propEq("tag", REMOTE_DATA_TAG.ERROR),

  isRemoteData: propEq("type", REMOTE_DATA_TYPE),
};

export default RemoteData;
