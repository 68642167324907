// 9fbef606107a605d69c0edbcd8029e5d

import { v4 as uuidv4 } from "uuid";
import {
  LOAD_CALLBACK_REMOTE_SLOTS,
  LOAD_CALLBACK_REMOTE_SLOTS_FAILURE,
  LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS,
  SET_CALLBACK_REMOTE_DAY,
  SET_CALLBACK_REMOTE_DISPLAY,
  SET_CALLBACK_REMOTE_SLOT,
} from "../../../../actions";
import * as reducers from "../../..";
import { getIANATimeZoneName } from "../../../../utils/time-zone";

const loadCallbackRemoteSlots = (id) => ({
  payload: { id },
  type: LOAD_CALLBACK_REMOTE_SLOTS,
});

const loadCallbackRemoteSlotsSuccess = (id, slots) => ({
  payload: { id, slots },
  type: LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS,
});

const loadCallbackRemoteSlotsFailure = (id, error) => ({
  error: true,
  payload: { id, error },
  type: LOAD_CALLBACK_REMOTE_SLOTS_FAILURE,
});

export const loadCallbackRemoteSlotsEffect = () => (
  dispatch,
  getState,
  { API }
) => {
  const connectorsBU = reducers.getConnectorsBU(getState());
  const country = reducers.getCountry(getState());
  const correlationId = uuidv4();
  const timeZone = getIANATimeZoneName();

  dispatch(loadCallbackRemoteSlots(connectorsBU));

  API.loadCallbackRemoteSlots({ country, correlationId, timeZone })
    .then((slots) =>
      dispatch(loadCallbackRemoteSlotsSuccess(connectorsBU, slots))
    )
    .catch((error) =>
      dispatch(loadCallbackRemoteSlotsFailure(connectorsBU, error?.status))
    );
};

export const setCallbackRemoteDay = (day, businessUnit) => ({
  payload: {
    businessUnit,
    day,
  },
  type: SET_CALLBACK_REMOTE_DAY,
});

export const setCallbackRemoteDisplay = (payload) => ({
  payload,
  type: SET_CALLBACK_REMOTE_DISPLAY,
});

export const setCallbackRemoteSlot = (payload) => ({
  payload,
  type: SET_CALLBACK_REMOTE_SLOT,
});
