// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import RD from "../../../utils/functional/remote-data";
import * as reducers from "../../../reducers";
import * as actions from "../../../reducers/actions";
import { MODALS } from "../../../utils/constants";
import ModalErrorCallbackRemote from ".";

const mapStateToProps = (state) => ({
  errorStatusCode: reducers.getCallbackRemoteSlotsError(state),
  isOpen: reducers.getShownModal(state) === MODALS.ERROR_CALLBACK_REMOTE,
  isRetrying: RD.isLoading(reducers.getCallbackRemoteSlots(state)),
});

const mapDispatchToProps = {
  onClose: actions.hideModalErrorCallbackRemote,
  onRetry: actions.loadCallbackRemoteSlotsEffect,
};

export default compose(
  memo,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ModalErrorCallbackRemote);
