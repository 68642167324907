// 9fbef606107a605d69c0edbcd8029e5d

import { memo } from "react";
import { compose } from "ramda";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Product from ".";
import * as reducers from "../../../reducers";

const mapStateToProps = (state) => ({
  activeStep: reducers.getActiveStep(state),
  fields: reducers.getProductFields(state),
  openLinksInSameTab: reducers.getOpenLinksInSameTab(state),
  options: reducers.getProductOptions(state),
  singleProduct: reducers.getSingleProduct(state),
  singleProductRerouting: reducers.getSingleProductRerouting(state),
  singleRegion: reducers.getSingleRegion(state),
  singleRegionRerouting: reducers.getSingleRegionRerouting(state),
});

export default compose(memo, connect(mapStateToProps), injectIntl)(Product);
