// 9fbef606107a605d69c0edbcd8029e5d

export const GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP";
export const GO_TO_PREVIOUS_STEP = "GO_TO_PREVIOUS_STEP";
export const HIDE_MODAL_CONNECTOR_SELECTOR = "HIDE_MODAL_CONNECTOR_SELECTOR";
export const HIDE_MODAL_ERROR = "HIDE_MODAL_ERROR";
export const HIDE_MODAL_ERROR_CALLBACK_REMOTE =
  "HIDE_MODAL_ERROR_CALLBACK_REMOTE";
export const LOAD_CALLBACK_REMOTE_SLOTS = "LOAD_CALLBACK_REMOTE_SLOTS";
export const LOAD_CALLBACK_REMOTE_SLOTS_FAILURE =
  "LOAD_CALLBACK_REMOTE_SLOTS_FAILURE";
export const LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS =
  "LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS";
export const LOAD_CONFIGURATION = "LOAD_CONFIGURATION";
export const LOAD_CONFIGURATION_FAILURE = "LOAD_CONFIGURATION_FAILURE";
export const LOAD_CONFIGURATION_SUCCESS = "LOAD_CONFIGURATION_SUCCESS";
export const SET_CALLBACK_REMOTE_DAY = "SET_CALLBACK_REMOTE_DAY";
export const SET_CALLBACK_REMOTE_DISPLAY = "SET_CALLBACK_REMOTE_DISPLAY";
export const SET_CALLBACK_REMOTE_SLOT = "SET_CALLBACK_REMOTE_SLOT";
export const SET_CALLBACK_REMOTE_SUBMIT_ERROR =
  "SET_CALLBACK_REMOTE_SUBMIT_ERROR";
export const SET_AB_TESTING = "SET_AB_TESTING";
export const LOAD_PHONE_NUMBER_VALIDATION_LIB =
  "LOAD_PHONE_NUMBER_VALIDATION_LIB";
export const LOAD_PHONE_NUMBER_VALIDATION_LIB_FAILURE =
  "LOAD_PHONE_NUMBER_VALIDATION_LIB_FAILURE";
export const LOAD_PHONE_NUMBER_VALIDATION_LIB_SUCCESS =
  "LOAD_PHONE_NUMBER_VALIDATION_LIB_SUCCESS";
export const LOAD_TRANSLATION = "LOAD_TRANSLATION";
export const LOAD_TRANSLATION_FAILURE = "LOAD_TRANSLATION_FAILURE";
export const LOAD_TRANSLATION_SUCCESS = "LOAD_TRANSLATION_SUCCESS";
export const NOTIFY_DECIBEL_FORM_REFRESH = "NOTIFY_DECIBEL_FORM_REFRESH";
export const NOTIFY_DECIBEL_FORM_SUBMIT = "NOTIFY_DECIBEL_FORM_SUBMIT";
export const TRIGGER_CONTENT_INTERACTION_TRACKING =
  "TRIGGER_CONTENT_INTERACTION_TRACKING";
export const TRIGGER_PAGE_VIEW_TRACKING = "TRIGGER_PAGE_VIEW_TRACKING";
export const OPEN_MODAL_CONNECTOR_SELECTOR = "OPEN_MODAL_CONNECTOR_SELECTOR";
export const POST_LEAD = "POST_LEAD";
export const POST_LEAD_FAILURE = "POST_LEAD_FAILURE";
export const POST_LEAD_SUCCESS = "POST_LEAD_SUCCESS";
export const POST_LEAD_RESET = "POST_LEAD_RESET";
export const PUSH_TO_DIGITAL_LAYER = "PUSH_TO_DIGITAL_LAYER";
export const QUERY_COUNTRY = "QUERY_COUNTRY";
export const REMOVE_SHIPMENT_PRODUCT = "REMOVE_SHIPMENT_PRODUCT";
export const SELECT_COUNTRY = "SELECT_COUNTRY";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_CONTACT_INFORMATION = "SET_CONTACT_INFORMATION";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PRODUCT_FREQUENCY = "SET_PRODUCT_FREQUENCY";
export const SET_PRODUCT_NUMBER = "SET_PRODUCT_NUMBER";
export const SET_PRODUCT_URGENCY = "SET_PRODUCT_URGENCY";
export const SET_PRODUCT_REGION = "SET_PRODUCT_REGION";
export const INIT_SCHEDULER = "INIT_SCHEDULER";
export const INIT_SCHEDULER_FROM_PROFILE = "INIT_SCHEDULER_FROM_PROFILE";
export const SET_SCHEDULER_DISPLAY = "SET_SCHEDULER_DISPLAY";
export const SET_SCHEDULER_DAY = "SET_SCHEDULER_DAY";
export const SET_SCHEDULER_SLOT = "SET_SCHEDULER_SLOT";
export const SET_SHIPMENT_PRODUCT = "SET_SHIPMENT_PRODUCT";
export const SET_VALIDATED_CONTACT_FORM = "SET_VALIDATED_CONTACT_FORM";
export const SET_VALIDATED_SHIPMENT_PRODUCT = "SET_VALIDATED_SHIPMENT_PRODUCT";
export const SET_VALIDATED_PRODUCT_DETAILS = "SET_VALIDATED_PRODUCT_DETAILS";
export const SET_VALIDATED_SHIPMENT_SCALE = "SET_VALIDATED_SHIPMENT_SCALE";
export const TRACK_EVENT = "TRACK_EVENT";
export const VALIDATE_CONTACT_INFORMATION = "VALIDATE_CONTACT_INFORMATION";
export const VALIDATE_COUNTRY = "VALIDATE_COUNTRY";
export const VALIDATE_PRODUCT_NUMBER = "VALIDATE_PRODUCT_NUMBER";
