import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  CheckmarkCircle as IconCheckmarkCircle,
  Plus as IconPlus,
} from "@dhl-official/icons";

// #region Component Styles
const CHECKBOX_SIZE = "30px";

const Container = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
  user-select: none;
  height: ${CHECKBOX_SIZE};
  width: ${CHECKBOX_SIZE};
`;

const CheckmarkIconContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  color: var(--dui-color-black-400);
  display: flex;
  height: ${CHECKBOX_SIZE};
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: border-color var(--dui-duration-default);
  width: ${CHECKBOX_SIZE};
`;

const IconPlusIconContainer = styled(CheckmarkIconContainer)`
  padding: 7px;

  &::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    border: 1px solid black;
    border-radius: 13px;
  }
`;

const StyledCheckmark = styled(IconCheckmarkCircle).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  color: var(--dui-color-green-400);
  height: inherit;
  transition: transform var(--dui-duration-default),
    opacity var(--dui-duration-default);
  vertical-align: middle;
  width: 100%;
`;

const StyledPlus = styled(IconPlus).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  height: inherit;
  transition: transform var(--dui-duration-default),
    opacity var(--dui-duration-default);
  width: 100%;
`;

const Check = styled.input.attrs({ type: "checkbox" })`
  left: -9999px;
  position: absolute;

  &:hover,
  &:focus,
  &:active {
    ~ ${CheckmarkIconContainer} {
      border-color: var(--dui-color-black-400);
    }
  }

  &:not(:checked) {
    ~ ${CheckmarkIconContainer} > ${StyledCheckmark} {
      opacity: 0;
      transform: scale(0);
    }

    ~ ${IconPlusIconContainer}
      > ${StyledPlus},
      ~ ${IconPlusIconContainer}::before {
      opacity: 1;
      transform: scale(1);
    }
  }

  &:checked {
    ~ ${CheckmarkIconContainer} > ${StyledCheckmark} {
      opacity: 1;
      transform: scale(1);
    }

    ~ ${IconPlusIconContainer}
      > ${StyledPlus},
      ~ ${IconPlusIconContainer}::before {
      opacity: 0;
      transform: scale(0);
    }
  }
`;
// #endregion

const CheckboxField = ({
  ariaDescribedBy,
  ariaInvalid,
  ariaLabel,
  className,
  dataTestid,
  dataTracking,
  id,
  isChecked,
  isRequired,
  name,
  onChange,
  tabIndex,
  value,
}) => (
  <Container className={className}>
    <Check
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
      aria-label={ariaLabel}
      checked={isChecked}
      data-testid={dataTestid}
      data-tracking={dataTracking}
      id={id}
      name={name}
      readOnly={!onChange}
      onChange={onChange}
      required={isRequired}
      tabIndex={tabIndex}
      value={value}
    />

    <IconPlusIconContainer
      data-testid={!isChecked ? `${dataTestid}-icon-plus` : null}
    >
      <StyledPlus />
    </IconPlusIconContainer>

    <CheckmarkIconContainer
      data-testid={isChecked ? `${dataTestid}-icon-checkmark` : null}
    >
      <StyledCheckmark />
    </CheckmarkIconContainer>
  </Container>
);

CheckboxField.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaInvalid: PropTypes.bool,
  ariaLabel: PropTypes.string,
  /** Class to the root element */
  className: PropTypes.string,
  /** The test id attached to the button as a data-testid attribute. */
  dataTestid: PropTypes.string,
  dataTracking: PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

CheckboxField.defaultProps = {
  isChecked: false,
};

export default CheckboxField;
