import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

// #region Component Styles
const LOADING = keyframes`
  0% {
    transform: translateX(-150%);
  }

  100% {
    transform: translateX(250%);
  }
`;

const Container = styled.div`
  background-color: var(--dui-color-white-500);
  padding-bottom: calc(var(--dui-size-space-7x) * 2);
  padding-top: calc(var(--dui-size-space-7x) * 5);
  font-size: var(--dui-size-font-md);
  width: 100%;
`;

const Activity = styled.div`
  position: absolute;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  animation: ${LOADING} 1s infinite;
`;

const Title = styled.div`
  background-color: var(--dui-color-gray-100);
  border-radius: var(--dui-size-radius-md);
  height: 42px;
  margin: 0 auto;
  max-width: 350px;
  overflow: hidden;
  padding-bottom: var(--dui-size-space-3x);
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  background-color: #f7f7f7;
  height: 337px;
  margin-top: 116px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  background-color: #f7f7f7;
  height: 100px;
  padding-bottom: var(--dui-size-space-3x);
  padding-top: var(--dui-size-space-10x);
  width: 100%;
`;

const Button = styled.div`
  background-color: var(--dui-color-gray-100);
  border-radius: var(--dui-size-radius-md);
  height: 48px;
  margin: 0 auto;
  max-width: 205px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;
// #endregion

const LoadingSkeleton = ({ trackFirstMeaningfulPaint }) => {
  useEffect(trackFirstMeaningfulPaint, []);

  return (
    <Container>
      <Title>
        <Activity />
      </Title>
      <Content />
      <ButtonWrapper>
        <Button>
          <Activity />
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

LoadingSkeleton.propTypes = {
  trackFirstMeaningfulPaint: PropTypes.func.isRequired,
};

export default LoadingSkeleton;
