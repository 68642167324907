// 9fbef606107a605d69c0edbcd8029e5d

import { v4 as uuidv4 } from "uuid";
import { WEEK_DAYS } from "./constants";

export const isDevMode = (mode) =>
  window.location.search.includes(`devMode=${mode}`);

export const slotToTime = (slot, intl) => {
  const slotAsString = slot.toString();

  const timeElement =
    slotAsString.length === 4
      ? `${slotAsString.substr(0, 2)}:${slotAsString.substr(-2)}`
      : `0${slotAsString.substr(0, 1)}:${slotAsString.substr(-2)}`;

  return intl ? intl.formatTime(`1970-01-01T${timeElement}`) : timeElement;
};

export const formatDateSlot = (slot, intl, flip) => {
  const finalSlot = flip ? [...slot].reverse() : slot;
  return finalSlot.map((elem) => intl.formatTime(elem)).join(" - ");
};

export const parseDateToOIPFormat = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const isBusinessDay = (currentDate, weekendDays) =>
  !weekendDays.includes(WEEK_DAYS[currentDate.getDay()]);

export const getCorrelationIds = () => {
  const correlationUUID = uuidv4().replace(/-/g, "");

  return {
    traceId: correlationUUID?.slice(0, 16) || "",
    spanId: correlationUUID?.slice(16, 32) || "",
    traceparent: `00-${correlationUUID?.slice(0, 32)}-${correlationUUID?.slice(
      0,
      16
    )}-01`,
  };
};
