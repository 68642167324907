// 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from "prop-types";
import { propEq } from "ramda";
import { useEffect, useMemo } from "react";
import RD from "../../utils/functional/remote-data";
import { STEPS } from "../../utils/constants";

export const encodeStepsHistory = (stepsHistory) =>
  stepsHistory
    .map((step) => {
      switch (step) {
        case STEPS.PRODUCT_DETAILS:
          return "SC";

        case STEPS.PRODUCT:
          return "PR";

        case STEPS.CONTACT_FORM:
          return "FO";

        case STEPS.CONNECTORS:
          return "CO";

        default:
          return undefined;
      }
    })
    .filter(Boolean);

const Tracking = ({
  businessUnitsLead,
  featureFlags,
  forcedCrossBUs,
  phoneNumberValidation,
  pushToDigitalLayer,
  shipmentProduct,
  shipmentScale,
  shownModal,
  stepsHistory,
  trackingData,
  validationStates,
  version,
  withScheduledCallback,
}) => {
  const encodedStepsHistory = useMemo(() => encodeStepsHistory(stepsHistory), [
    stepsHistory,
  ]);

  useEffect(() => {
    const hasAnyInvalidField = Object.values(validationStates).some(
      propEq("hasError", true)
    );

    if (hasAnyInvalidField) {
      document.dispatchEvent(new CustomEvent("gaq-fs-validation-error"));
    }
  }, [validationStates]);

  const buildDigitalLayer = () => {
    const {
      perfFirstConsistentlyInteractiveinMs,
      perfFirstContentfulPaint,
      perfFirstMeaningfulPaint,
      perfFirstPaint,
      perfLongtask,
      perfPerceivedPageLoadTime,
      perfRequestTime,
      perfResponseTime,
      perfTotalRequestResponseTime,
      regularShipmentLPStep1NextClicked,
      regularShipmentLPStep2NextClicked,
      regularShipmentLPStep2PreviousClicked,
      regularShipmentLPStep3PreviousClicked,
      regularShipmentLPStep3SubmitClicked,
      regularShipmentNoBusinessUnitsModalCancelButtonClicked,
      regularShipmentNoBusinessUnitsModalContactUsClicked,
      regularShipmentNoBusinessUnitsModalRetryButtonClicked,
      selfOnboardingButtonClicked,
      successModalClosedAt,
      successModalselfOnboardingBUs,
      successModalShownAt,
      successModalVariationShown,
      trackingId,
    } = trackingData;

    return {
      regularShipmentLP: {
        featureToggles: RD.withDefault({}, featureFlags),
        forcedCrossBUs,
        interactions: {
          noBusinessUnitsModal: {
            cancelButtonClicked: regularShipmentNoBusinessUnitsModalCancelButtonClicked,
            contactLinkClicked: regularShipmentNoBusinessUnitsModalContactUsClicked,
            retryButtonClicked: regularShipmentNoBusinessUnitsModalRetryButtonClicked,
          },
          phoneNumberValidation,
          rampOff: { selfOnboardingButtonClicked },
          regularShipmentLPStep1NextClicked,
          regularShipmentLPStep2NextClicked,
          regularShipmentLPStep2PreviousClicked,
          regularShipmentLPStep3PreviousClicked,
          regularShipmentLPStep3SubmitClicked,
          stepsHistory: encodedStepsHistory,
          successModal: {
            modalClosedAt: successModalClosedAt,
            modalShownAt: successModalShownAt,
            selfOnboardingBUs: successModalselfOnboardingBUs,
            variationShown: successModalVariationShown,
            withScheduledCallback,
          },
          validationStates,
        },
        networkData: { businessUnitsLead },
        requestData: { shownModal, shipmentScale, shipmentProduct },
        trackingId,
        version,
      },
      performance: {
        uiMetrics: {
          firstConsistentlyInteractiveinMs: perfFirstConsistentlyInteractiveinMs,
          firstContentfulPaint: perfFirstContentfulPaint,
          firstMeaningfulPaint: perfFirstMeaningfulPaint,
          firstPaint: perfFirstPaint,
          longtask: perfLongtask,
          perceivedPageLoadTime: perfPerceivedPageLoadTime,
          requestTime: perfRequestTime,
          responseTime: perfResponseTime,
          totalRequestResponseTime: perfTotalRequestResponseTime,
        },
      },
    };
  };

  useEffect(() => {
    pushToDigitalLayer(buildDigitalLayer());
  });

  return null;
};

Tracking.propTypes = {
  businessUnitsLead: PropTypes.array.isRequired,
  featureFlags: PropTypes.object.isRequired,
  forcedCrossBUs: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  phoneNumberValidation: PropTypes.shape({
    country: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    length: PropTypes.number.isRequired,
  }),
  pushToDigitalLayer: PropTypes.func.isRequired,
  shipmentProduct: PropTypes.object.isRequired,
  shipmentScale: PropTypes.object.isRequired,
  shownModal: PropTypes.string,
  stepsHistory: PropTypes.array.isRequired,
  trackingData: PropTypes.object.isRequired,
  validationStates: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired,
  withScheduledCallback: PropTypes.bool,
};

export default Tracking;
