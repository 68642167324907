import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { STEPS, A11Y_CURRENT_STEP_ELEMENT } from "../../../utils/constants";

const Container = styled.div.attrs({
  "aria-live": "assertive",
  "aria-relevant": "text",
  className: "visually-hidden",
  role: "status",
  id: A11Y_CURRENT_STEP_ELEMENT,
  tabIndex: "0",
})``;

const ScreenReaderAnnouncement = ({
  activeStep,
  activeStepNumber,
  numberOfSteps,
  profile,
}) => (
  <Container data-stepid={activeStep} data-profile={profile}>
    {activeStep === STEPS.THANK_YOU ? (
      <FormattedMessage id="FS2.ThankYou.screenReaderLabel_thankYouPageDescription" />
    ) : (
      <FormattedMessage
        id="FS2.MultiStepsIndicator.screenReaderLabel_currentStep"
        values={{ stepIndex: activeStepNumber, numberOfSteps }}
      />
    )}
  </Container>
);

ScreenReaderAnnouncement.propTypes = {
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  activeStepNumber: PropTypes.number,
  numberOfSteps: PropTypes.number.isRequired,
  profile: PropTypes.string.isRequired,
};

export default ScreenReaderAnnouncement;
