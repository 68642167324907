import React, { memo } from "react";
import PropTypes from "prop-types";
import { DhlValidationFeedback } from "@dhl-official/react-library";
import styled, { css } from "styled-components";

// #region Component Styles
const Container = styled.div`
  display: flex;
`;

const Frequency = styled.button.attrs({ type: "button" })`
  background-color: var(--dui-color-white-500);
  border: solid 1px var(--dui-color-gray-600);
  border-color: ${({ $isInvalid }) =>
    $isInvalid ? "var(--dui-color-red-500)" : "var(--dui-color-gray-500)"};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: var(--dui-color-gray-600);
  cursor: pointer;
  flex-grow: 1;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  margin-left: -1px;
  outline: 0;
  overflow: hidden;
  padding: var(--dui-size-space-6x) 4px;
  pointer-events: all;
  width: calc(100% / 3);
  text-overflow: ellipsis;
  transition: background-color var(--dui-duration-default),
    border-color var(--dui-duration-default), color var(--dui-duration-default);
  white-space: nowrap;

  &:focus {
    outline: 2px solid var(--dui-color-black-400);
    outline-offset: -2px;
    border-color: transparent;
  }

  &:hover {
    color: var(--dui-color-black-400);
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: var(--dui-color-red-500);
      border-color: var(--dui-color-red-500);
      border-width: 2px;
      color: var(--dui-color-white-500);
      padding: calc(var(--dui-size-space-6x) - 1px) 3px;
      position: relative;
      z-index: 1;
      :focus,
      :hover {
        color: var(--dui-color-white-500);
      }
    `};

  &:first-of-type {
    border-bottom-left-radius: var(--dui-size-radius-md);
    border-top-left-radius: var(--dui-size-radius-md);
  }

  &:last-of-type {
    border-bottom-right-radius: var(--dui-size-radius-md);
    border-top-right-radius: var(--dui-size-radius-md);
  }
`;
// #endregion

const FrequencySelector = ({
  intl,
  options,
  product,
  selectedFrequency,
  setSelectedFrequency,
  trackingPath,
  isInvalid,
  validationFeedback,
}) => {
  return (
    <div>
      <Container>
        {options.map((f) => (
          <Frequency
            data-testid={`shipmentProduct_${product}_frequency_${f.key}`}
            data-testselected={f.key === selectedFrequency ? "true" : "false"}
            data-tracking={`${trackingPath}.frequency`}
            key={f.key}
            $isInvalid={isInvalid}
            $isSelected={f.key === selectedFrequency}
            onClick={() => setSelectedFrequency(f.key)}
            title={f.label}
          >
            {f.label}
          </Frequency>
        ))}
      </Container>
      {isInvalid && (
        <DhlValidationFeedback
          dataTestid={`shipmentProduct-${product}-frequency-validationMessage`}
          validation={{
            type: "invalid",
            message: intl.formatMessage({
              id: validationFeedback.feedbackMessageId,
            }),
          }}
        />
      )}
    </div>
  );
};

FrequencySelector.propTypes = {
  intl: PropTypes.object.isRequired,
  isInvalid: PropTypes.bool,
  options: PropTypes.array.isRequired,
  product: PropTypes.string.isRequired,
  selectedFrequency: PropTypes.string,
  setSelectedFrequency: PropTypes.func.isRequired,
  trackingPath: PropTypes.string.isRequired,
  validationFeedback: PropTypes.object,
};

export default memo(FrequencySelector);
