import React, { memo } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { DhlButton, DhlHeadline, DhlText } from "@dhl-official/react-library";
import { Exception as IconException } from "@dhl-official/icons";
import Modal from "../../Modal";
import ButtonLoader from "../../ButtonLoader";
import {
  CALLBACK_REMOTE_SLOTS_HTTP_STATUS_CODES,
  STYLES_VIEWPORTS,
} from "../../../utils/constants";

// #region Component Styles
const LOADING = keyframes`
0% {
  transform: translateX(-150%);
}

100% {
  transform: translateX(250%);
}
`;

const StyledModal = styled(Modal)`
  &.dhl-cj-fs {
    direction: ltr;
    max-width: 100%;
    min-height: auto;
    max-height: none;
    width: 95vw;

    @media screen and (max-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      padding: 30px 20px;
    }

    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      max-width: 600px;
      padding: calc(var(--dui-size-space-3x) * 9)
        calc(var(--dui-size-space-3x) * 13);
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row;
  }
`;

const Warning = styled(IconException).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  color: var(--dui-color-red-500);
  height: 76px;
  margin-bottom: var(--dui-size-space-10x);
  vertical-align: middle;
  width: 76px;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    margin-bottom: 0;
    margin-left: calc(var(--dui-size-space-10x) * -2);
    margin-right: calc(var(--dui-size-space-10x) * 2);
    min-width: 76px;
  }
`;

const TextContent = styled.div``;

const Title = styled(DhlHeadline).attrs({
  designLevel: 3,
  dataTestid: "modal-error-callback-remote-headline",
  tag: "h3",
})``;

const Description = styled(DhlText).attrs({
  isParagraph: true,
})`
  & p {
    margin-top: var(--dui-size-space-7x);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const StyledButton = styled(DhlButton)`
  & button {
    margin-top: var(--dui-size-space-10x);
    margin-right: var(--dui-size-space-7x);
  }
`;

const LoadingSkeleton = styled.div`
  margin-top: var(--dui-size-space-7x);
`;

const LoadingText = styled.div`
  background-color: var(--dui-color-gray-100);
  border-radius: 2px;
  display: block;
  height: 16px;
  margin: 0 0 10px 0;
  overflow: hidden;
  width: ${({ $width }) => $width || "auto"};
`;

const LoadingActivity = styled.div`
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  animation: ${LOADING} 1s infinite;
`;
// #endregion

const ModalErrorCallbackRemote = ({
  errorStatusCode,
  intl,
  isOpen,
  isRetrying,
  onClose,
  onRetry,
}) => (
  <StyledModal
    className="dhl-cj-fs"
    closeButtonAriaLabel={intl.formatMessage({
      id: "FS2.ContentModal.close",
    })}
    contentAriaLabel={intl.formatMessage({
      id: "FS2.ModalErrorCallbackRemote.headline",
    })}
    isOpen={isOpen}
    onClose={onClose}
    overlayColor="rgba(204, 204, 204, 0.9)"
    shouldShowCloseButton
  >
    <Content>
      <Warning />

      <TextContent>
        <Title>
          <FormattedMessage id="FS2.ModalErrorCallbackRemote.headline" />
        </Title>

        {!isRetrying ? (
          <Description>
            <FormattedMessage
              id={
                errorStatusCode?.toString() ===
                CALLBACK_REMOTE_SLOTS_HTTP_STATUS_CODES.NO_CONTENT
                  ? "FS2.ModalErrorCallbackRemote.copyNoContent"
                  : "FS2.ModalErrorCallbackRemote.copy"
              }
              values={{
                br: <br />,
              }}
            />
          </Description>
        ) : (
          <LoadingSkeleton>
            {[100, 70, 30].map((n) => (
              <LoadingText key={n} $width={`${n}%`}>
                <LoadingActivity />
              </LoadingText>
            ))}
          </LoadingSkeleton>
        )}

        <ButtonsContainer>
          <StyledButton
            isDisabled={isRetrying}
            clickEvent={onRetry}
            variant="primary"
            dataTestid="modal-error-callback-remote-retry-button"
          >
            <div>
              {!isRetrying ? (
                <FormattedMessage id="FS2.ModalErrorCallbackRemote.retry" />
              ) : (
                <ButtonLoader />
              )}
            </div>
          </StyledButton>
          <StyledButton
            clickEvent={onClose}
            variant="outline"
            dataTestid="modal-error-callback-remote-cancel-button"
          >
            <FormattedMessage id="FS2.ModalErrorCallbackRemote.cancel" />
          </StyledButton>
        </ButtonsContainer>
      </TextContent>
    </Content>
  </StyledModal>
);

ModalErrorCallbackRemote.propTypes = {
  errorStatusCode: PropTypes.number,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  isRetrying: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func,
};

ModalErrorCallbackRemote.defaultProps = {
  isOpen: false,
  isRetrying: false,
  onRetry: Function.prototype,
};

export default memo(injectIntl(ModalErrorCallbackRemote));
