// 9fbef606107a605d69c0edbcd8029e5d

import { memo } from "react";
import { compose } from "ramda";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ShipmentScale from ".";
import * as reducers from "../../../reducers";

const mapStateToProps = (state) => ({
  activeStep: reducers.getActiveStep(state),
  contentPrefix: reducers.getContentPrefix(state),
  freightNoMatches: reducers.getFreightNoMatches(state),
  mailNoMatches: reducers.getMailNoMatches(state),
  options: reducers.getProductOptions(state),
  parcelNoMatches: reducers.getParcelNoMatches(state),
  productFields: reducers.getProductFields(state),
  profile: reducers.getProfile(state),
  shouldShowOptions: reducers.getShouldShowOptions(state),
});

export default compose(
  memo,
  connect(mapStateToProps, null),
  injectIntl
)(ShipmentScale);
