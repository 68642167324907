import React, { memo, useReducer, createRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { DhlInputField } from "@dhl-official/react-library";
import scrollIntoView from "dom-scroll-into-view";

// #region Component Styles
const Container = styled.div`
  position: relative;
  margin-bottom: var(--dui-size-space-7x);
  width: 100%;
`;

const ScreenReaderOnly = styled.span`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
`;

const Menu = styled.ul.attrs({ role: "listbox" })`
  background-color: var(--dui-color-white-500);
  border-bottom-left-radius: var(--dui-size-radius-md);
  border-bottom-right-radius: var(--dui-size-radius-md);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: solid 1px var(--dui-color-gray-400);
  border: solid 2px var(--dui-color-black-400);
  list-style: none;
  margin-bottom: 0;
  margin-left: -1px;
  margin-top: -3px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: calc(100% + 2px);
  z-index: 11;
`;

const MenuItem = styled.li.attrs({ role: "option" })``;

const Option = styled.div`
  background-color: ${({ $isHighlighted }) =>
    $isHighlighted ? "var(--dui-color-gray-100)" : "transparent"};

  border: 0;
  display: block;
  outline: 0;
  padding-bottom: var(--dui-size-space-3x);
  padding-left: var(--dui-size-space-7x);
  padding-right: var(--dui-size-space-7x);
  padding-top: var(--dui-size-space-3x);
  text-align: left;
  width: 100%;
`;
// #endregion

const INITIAL_STATE = {
  ignoreInputBlur: false,
  index: -1,
  shouldShowSuggestions: false,
};

const ACTIONS = {
  SELECT_OPTION: "SELECT_OPTION",
  SET_SHOULD_SHOW_SUGGESTIONS: "SET_SHOULD_SHOW_SUGGESTIONS",
  SET_IGNORE_INPUT_BLUR: "SET_IGNORE_INPUT_BLUR",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_OPTION:
      return {
        ignoreInputBlur: false,
        index: -1,
        shouldShowSuggestions: false,
      };

    case ACTIONS.SET_SHOULD_SHOW_SUGGESTIONS:
      return {
        ...state,
        shouldShowSuggestions: action.payload,
      };

    case ACTIONS.SET_IGNORE_INPUT_BLUR:
      return {
        ...state,
        ignoreInputBlur: action.payload,
      };

    case ACTIONS.SET_INDEX:
      return {
        ...state,
        index: action.payload,
      };

    default:
      return state;
  }
};

const AutoCompleteField = ({
  ariaDescribe,
  ariaLabel,
  className,
  dataTestid,
  id,
  isRequired,
  name,
  numberOfOptionsCopy,
  onBlur,
  onChange,
  onOptionSelected,
  options,
  renderOption,
  validation,
  value,
  variant,
}) => {
  const autoCompleteId = `${name}-auto-complete`;
  const inputFieldRef = createRef();
  const menuRef = createRef();
  const numberOfOptions = options.length;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { ignoreInputBlur, index, shouldShowSuggestions } = state;

  const setIndex = (payload) => dispatch({ type: ACTIONS.SET_INDEX, payload });

  const setShouldShowSuggestions = (payload) =>
    dispatch({ type: ACTIONS.SET_SHOULD_SHOW_SUGGESTIONS, payload });

  const setIgnoreInputBlur = (payload) =>
    dispatch({ type: ACTIONS.SET_IGNORE_INPUT_BLUR, payload });

  const isShowingSuggestions = options.length > 0 && shouldShowSuggestions;

  useEffect(() => {
    if (options.length) {
      setIndex(0);
    }
  }, [options, value]);

  useEffect(() => {
    if (shouldShowSuggestions && options.length > 0) {
      const elements = menuRef.current.children;
      const listItem = elements[index];

      if (listItem) {
        const button = listItem.querySelector(Option.toString());
        scrollIntoView(button, menuRef.current, { onlyScrollIfNeeded: true });
      }
    }

    if (!shouldShowSuggestions) {
      setIgnoreInputBlur(false);
    }
  }, [index, options, shouldShowSuggestions]);

  const selectOption = (o) => {
    onOptionSelected(o);
    dispatch({ type: ACTIONS.SELECT_OPTION });

    inputFieldRef.current.focus();
  };

  const onInputBlur = () => {
    setShouldShowSuggestions(false);
    onBlur();
  };

  const onInputFocus = () => {
    setShouldShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    setShouldShowSuggestions(true);

    switch (e.key) {
      case "ArrowDown": {
        e.preventDefault();
        setIndex((index + 1) % options.length);
        break;
      }

      case "ArrowUp": {
        e.preventDefault();
        setIndex(index === 0 ? options.length - 1 : index - 1);
        break;
      }

      case "Enter": {
        if (options[index]) {
          e.preventDefault();
          selectOption(options[index]);
        }

        break;
      }

      case "Escape":
      case "Tab": {
        setShouldShowSuggestions(false);
        break;
      }

      default:
        break;
    }
  };

  return (
    <Container className={className}>
      <ScreenReaderOnly id={`${name}-instructions`}>
        {ariaDescribe}
      </ScreenReaderOnly>

      <DhlInputField
        dataAriaActivedescendant={
          index !== -1 && isShowingSuggestions
            ? `${name}-suggestions-${index}`
            : undefined
        }
        dataAriaAutoComplete="list"
        dataAriaDescribedby={!value ? `${name}-instructions` : undefined}
        dataAriaExpanded={isShowingSuggestions ? "true" : "false"}
        dataAriaLabel={ariaLabel}
        dataAriaOwns={autoCompleteId}
        autoComplete="off"
        dataTestid={dataTestid}
        dataId={id}
        isRequired={isRequired}
        name={name}
        onDhlBlur={!ignoreInputBlur ? onInputBlur : undefined}
        onDhlInput={onChange}
        onDhlFocus={!ignoreInputBlur ? onInputFocus : undefined}
        onDhlKeyDown={handleKeyDown}
        ref={inputFieldRef}
        dataRole="combobox"
        type="text"
        validation={
          isShowingSuggestions && validation
            ? { type: validation.type }
            : validation
        }
        value={value}
        variant={variant}
      />

      {shouldShowSuggestions && value && (
        <ScreenReaderOnly
          aria-atomic="true"
          aria-live="assertive"
          role="status"
        >
          {`${options.length} ${numberOfOptionsCopy}`}
        </ScreenReaderOnly>
      )}

      {isShowingSuggestions && (
        <Menu
          id={autoCompleteId}
          onMouseEnter={() => setIgnoreInputBlur(true)}
          onMouseLeave={() => setIgnoreInputBlur(false)}
          onTouchStart={() => setIgnoreInputBlur(true)}
          ref={menuRef}
        >
          {options.map((o, optionIndex) => (
            <MenuItem
              id={`${name}-suggestions-${optionIndex}`}
              aria-posinset={optionIndex + 1}
              aria-selected={index === optionIndex}
              aria-setsize={numberOfOptions}
              key={o.id}
            >
              <Option
                $isHighlighted={index === optionIndex}
                onClick={() => selectOption(o)}
                onFocus={() => setIndex(optionIndex)}
                onMouseOver={() => setIndex(optionIndex)}
              >
                {renderOption(o)}
              </Option>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Container>
  );
};

AutoCompleteField.propTypes = {
  ariaDescribe: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  numberOfOptionsCopy: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onOptionSelected: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired })
  ),
  renderOption: PropTypes.func,
  validation: PropTypes.object,
  value: PropTypes.string,
  variant: PropTypes.object,
};

AutoCompleteField.defaultProps = {
  isRequired: false,
  onOptionSelected: Function.prototype,
  options: [],
  renderOption: Function.prototype,
};

export default memo(AutoCompleteField);
