import React from "react";
import PropTypes from "prop-types";
import { createGlobalStyle } from "styled-components";
import "@dhl-official/tokens/base.css";
import "@dhl-official/tokens/animations.css";
import "@dhl-official/tokens/font-face.css";
import {
  getAssistantFontStyles,
  getArabicFontStyles,
  getVietnameseFontStyles,
  getMyDHLPlusThemeStyles,
} from "./themes";
import {
  LOCALES_FONT_ASSISTANT,
  LOCALES_FONT_ARABIC,
  LOCALES_FONT_VIETNAMESE,
  THEMES,
} from "../utils/constants";

const { MOUNT_ID } = process.env;

// #region Component Styles
const GlobalCss = createGlobalStyle`

  /* App specific settings */
  #${MOUNT_ID}, .dhl-cj-fs {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: antialiased;
    font-family: var(--dui-font-family);
    font-size: var(--dui-size-font-md);
    line-height: initial;
    direction: ltr;

    --fs-application-container-padding-top: calc(var(--dui-size-space-7x) * 5);
    --fs-application-connector-margin-bottom: var(--dui-size-space-7x);

    --fs-form-container-background-color: #f7f7f7;
    --fs-connectors-icon-color: var(--dui-color-black-500);
    --fs-button-background-color: var(--dui-color-red-500);
    --fs-button-background-color-secondary: var(--dui-color-red-400);
    --fs-successbox-background: linear-gradient(
      180deg,
      #ffcc00 0%,
      #ffcc00 30%,
      #ffe57f 79%,
      #fff0b2 100%
    );
    --fs-successbox-boxshadow: 0 50px 30px -35px rgba(0, 0, 0, 0.16),
      2px 10px 100px 0 rgba(0, 0, 0, 0.1);

    /* Intentional override to workaround a visual issue in the DUIL DhlInputFiel - DHLPL-2258 */
    --dui-size-width-border-xs: var(--dui-size-space-1);

    ${({ theme }) => theme === THEMES.MYDHLPLUS && getMyDHLPlusThemeStyles()};

    ${({ locale }) => {
      const getFontStyles = () => {
        const language = locale.split("-")[0]; // Extracting language part from the locale

        if (LOCALES_FONT_ASSISTANT.includes(language)) {
          // Apply Assistant font styles
          return getAssistantFontStyles();
        }

        if (LOCALES_FONT_ARABIC.includes(language)) {
          // Apply Arabic font styles
          return getArabicFontStyles();
        }

        if (LOCALES_FONT_VIETNAMESE.includes(language)) {
          // Apply Vietnamese font styles
          return getVietnameseFontStyles();
        }

        // Apply default font styles for other languages
        return ""; // Default font styles if no match
      };

      return getFontStyles(locale);
    }}; 

    .visually-hidden {
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px !important;
      overflow: hidden;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important; /* stylelint-disable-line declaration-no-important */
      animation-iteration-count: 1 !important; /* stylelint-disable-line declaration-no-important */
      transition-duration: 0.01ms !important; /* stylelint-disable-line declaration-no-important */
    }
  }
`;
// #endregion

const Component = ({ locale, theme }) => (
  <GlobalCss locale={locale} theme={theme} />
);

Component.propTypes = {
  locale: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

export default Component;
