import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ButtonLoader from "../../ButtonLoader";
import {
  AB_TESTING_CAMPAIGNS,
  PROFILES,
  STEPS,
  STYLES_VIEWPORTS,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
} from "../../../utils/constants";
import { trackContentInteraction } from "../../../utils/track-utf";
import NextButton, { getNextButtonContentId } from "./NextButton";
import PreviousButton from "./PreviousButton";

// #region Component Styles
export const Container = styled.div`
  align-items: center;
  background-color: var(--fs-form-container-background-color);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: calc(var(--dui-size-space-7x) * 2);
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row-reverse;
  }
`;

export const getNextButtonDataTestid = (id, step, shouldShowConnectorsStep) => {
  if (step === 3 && shouldShowConnectorsStep) {
    return "btn-show-connectors";
  }
  return `${id}-button-proceed-${step}`;
};

export const getNextButtonDataTracking = (
  connectorsBU,
  id,
  step,
  shouldShowConnectorsStep
) => {
  if (step === 3 && shouldShowConnectorsStep) {
    return `regularShipmentLP.interactions.connectors.${connectorsBU}.showOptionsButtonClicked`;
  }
  return `${id}-button-proceed`;
};

const triggerClickTrackingEvent = (profile, name, activeStep) => {
  trackContentInteraction({
    context: profile,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: activeStep,
  });
};

const getAbTestContent = (abTesting, activeStep) => {
  if (abTesting === AB_TESTING_CAMPAIGNS.CTA_COPY) {
    if (activeStep === STEPS.PRODUCT_DETAILS) {
      return "See Service Match";
    }
    if (activeStep === STEPS.SERVICES) {
      return "Get a Custom Offer";
    }
  }
  return null;
};

const StepNavigator = ({
  abTesting,
  activeStep,
  contentPrefix,
  connectorsBU,
  id,
  isWaitingForEndpoint,
  onPrevious,
  profile,
  shouldHidePreviousStep,
  shouldHideProceedStep,
  shouldShowConnectorsStep,
  step,
}) => {
  const abTestContent = getAbTestContent(abTesting, activeStep);
  return (
    <Container>
      {!shouldHideProceedStep && (
        <NextButton
          dataTestid={getNextButtonDataTestid(
            id,
            step,
            shouldShowConnectorsStep
          )}
          dataTracking={getNextButtonDataTracking(
            connectorsBU,
            id,
            step,
            shouldShowConnectorsStep
          )}
          name={`${id}-button-proceed`}
          onClick={() =>
            triggerClickTrackingEvent(profile, UTF_EVENT_NAMES.NEXT, activeStep)
          }
        >
          <div>
            {!isWaitingForEndpoint ? (
              abTestContent || (
                <FormattedMessage
                  id={getNextButtonContentId(
                    activeStep,
                    contentPrefix,
                    profile
                  )}
                />
              )
            ) : (
              <ButtonLoader />
            )}
          </div>
        </NextButton>
      )}
      {!shouldHidePreviousStep && (
        <PreviousButton
          dataTestid={`${id}-button-previous-${step}`}
          dataTracking={`${id}-button-previous`}
          name={`${id}-button-previous`}
          onClick={() => {
            onPrevious();
            triggerClickTrackingEvent(
              profile,
              UTF_EVENT_NAMES.PREVIOUS,
              activeStep
            );
          }}
        />
      )}
    </Container>
  );
};

StepNavigator.propTypes = {
  abTesting: PropTypes.string,
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  connectorsBU: PropTypes.string,
  contentPrefix: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isWaitingForEndpoint: PropTypes.bool,
  onPrevious: PropTypes.func.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  shouldHidePreviousStep: PropTypes.bool,
  shouldHideProceedStep: PropTypes.bool,
  shouldShowConnectorsStep: PropTypes.bool,
  step: PropTypes.number.isRequired,
};

StepNavigator.defaultProps = {
  shouldHidePreviousStep: false,
  shouldHideProceedStep: false,
};

export default memo(StepNavigator);
