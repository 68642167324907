// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as reducers from "../../../reducers";
import * as actions from "../../../reducers/actions";
import { MODALS } from "../../../utils/constants";
import ModalConnectorSelector from ".";

const mapStateToProps = (state) => ({
  isOpen: reducers.getShownModal(state) === MODALS.CONNECTOR_SELECTOR,
});

const mapDispatchToProps = {
  onClose: actions.hideModalConnectorSelector,
};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ModalConnectorSelector);
