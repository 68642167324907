// 9fbef606107a605d69c0edbcd8029e5d

import { compose, head, identity, keys, prop, sortBy } from "ramda";
import {
  HIDE_MODAL_CONNECTOR_SELECTOR,
  LOAD_CALLBACK_REMOTE_SLOTS,
  LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS,
  LOAD_CALLBACK_REMOTE_SLOTS_FAILURE,
  SET_CALLBACK_REMOTE_DAY,
  SET_CALLBACK_REMOTE_DISPLAY,
  SET_CALLBACK_REMOTE_SLOT,
  SET_CALLBACK_REMOTE_SUBMIT_ERROR,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const INITIAL_STATE = {
  day: null,
  display: false,
  options: {},
  slot: null,
  submitError: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case HIDE_MODAL_CONNECTOR_SELECTOR:
      return {
        ...state,
        day: null,
        display: false,
        submitError: null,
        slot: null,
      };

    case LOAD_CALLBACK_REMOTE_SLOTS:
      return {
        ...state,
        options: {
          [payload.id]: RD.loading(),
        },
      };

    case LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS: {
      const { id, slots } = payload;
      const firstDay = compose(head, sortBy(identity), keys)(slots);
      const firstSlot = slots[firstDay][0];
      return {
        ...state,
        day: firstDay,
        display: true,
        options: {
          [id]: RD.success({
            ...slots,
          }),
        },
        slot: firstSlot,
      };
    }

    case LOAD_CALLBACK_REMOTE_SLOTS_FAILURE:
      return {
        ...state,
        options: {
          [payload.id]: RD.error(payload.error),
        },
      };

    case SET_CALLBACK_REMOTE_DAY: {
      // Check if the currently selected slot is also available for the selected day
      // and if not, we select not only the day but also the first available slot for it.
      const { day, businessUnit } = payload;
      const slotsOfTheDay = compose(
        prop(day),
        RD.withDefault({})
      )(state.options[businessUnit]);

      return {
        ...state,
        day,
        submitError: null,
        slot: slotsOfTheDay.includes(state.slot)
          ? state.slot
          : slotsOfTheDay[0],
      };
    }

    case SET_CALLBACK_REMOTE_DISPLAY: {
      return {
        ...state,
        display: payload,
      };
    }

    case SET_CALLBACK_REMOTE_SLOT:
      return {
        ...state,
        submitError: null,
        slot: payload,
      };

    case SET_CALLBACK_REMOTE_SUBMIT_ERROR:
      return {
        ...state,
        submitError: payload,
      };

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
