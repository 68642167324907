// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";

const INITIAL_STATE = null;

const reducer = (pageNameLegacy = INITIAL_STATE) => () => pageNameLegacy;

export const get = identity;

export default reducer;
