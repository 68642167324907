// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import ParcelOptions from ".";
import * as actions from "../../../../reducers/actions";

const mapDispatchToProps = {
  onChange: actions.setProductUrgency,
};

export default compose(
  memo,
  connect(undefined, mapDispatchToProps)
)(ParcelOptions);
