// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import { LOAD_TRANSLATION } from "../../../actions";

const INITIAL_STATE = "";

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_TRANSLATION:
      return payload;

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
