// 9fbef606107a605d69c0edbcd8029e5d

import { DHL_DOMAINS } from "../constants";

export const isNotDhlUrl = (hostname) =>
  !hostname.includes(DHL_DOMAINS.PROD_DOMAIN) &&
  !hostname.includes(DHL_DOMAINS.LOWER_ENV_DOMAIN);

export const isRelativeUrl = (url) =>
  !(url.indexOf("http://") === 0) && !(url.indexOf("https://") === 0);

export const createAbsoluteUrl = (url) =>
  isNotDhlUrl(window.location.hostname) && isRelativeUrl(url)
    ? `${DHL_DOMAINS.DHL_BASE_URL}${url}`
    : url;
