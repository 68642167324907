// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable no-param-reassign */

import { compose } from "ramda";
import { PUSH_TO_DIGITAL_LAYER, TRACK_EVENT } from "../../../../actions";

const NOOP = Function.prototype;

const PERFORMANCE_HANDLERS = {
  navigation: ({
    fetchStart,
    loadEventEnd,
    requestStart,
    responseEnd,
    responseStart,
  }) => ({
    perfPerceivedPageLoadTime: Math.round(loadEventEnd - fetchStart),
    perfRequestTime: responseStart - requestStart,
    perfResponseTime: responseEnd - responseStart,
    perfTotalRequestResponseTime: Math.round(responseEnd - requestStart),
  }),

  paint: ({ name, startTime, duration }) => {
    const keys = {
      "first-paint": "perfFirstPaint",
      "first-contentful-paint": "perfFirstContentfulPaint",
    };

    return {
      [keys[name]]: Math.round(startTime + duration),
    };
  },

  longtask: ({ startTime, duration }) => ({
    perfLongtask: Math.round(startTime + duration),
  }),
};

const pushToDigitalLayer = (payload) => ({
  payload,
  type: PUSH_TO_DIGITAL_LAYER,
});

export const trackEvent = (payload) => ({
  payload,
  type: TRACK_EVENT,
});

const trackFirstConsistentlyInteractiveinMsEffect = () => (
  dispatch,
  _,
  { getFirstConsistentlyInteractive }
) =>
  getFirstConsistentlyInteractive()
    .then((tti) => ({
      perfFirstConsistentlyInteractiveinMs: Math.round(tti),
    }))
    .then(compose(dispatch, trackEvent));

export const trackUiMetricsEffect = () => (
  dispatch,
  _,
  { PerformanceObserver }
) => {
  dispatch(trackFirstConsistentlyInteractiveinMsEffect());

  if (PerformanceObserver) {
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        const payload = (PERFORMANCE_HANDLERS[entry.entryType] || NOOP)(entry);

        if (payload) {
          dispatch(trackEvent(payload));
        }
      });
    });

    observer.observe({ entryTypes: Object.keys(PERFORMANCE_HANDLERS) });
  }
};

export const trackFirstMeaningfulPaintEffect = () => (
  dispatch,
  _,
  { performanceAPI }
) => {
  dispatch(
    trackEvent({
      perfFirstMeaningfulPaint: performanceAPI
        ? Math.round(performanceAPI.now())
        : new Date().toISOString(),
    })
  );
};

export const pushToDigitalLayerEffect = (payload) => (
  dispatch,
  _,
  { window }
) => {
  if (!window.digitalLayer) {
    window.digitalLayer = {};
  }
  if (!window.digitalLayer.gaq) {
    window.digitalLayer = { ...window.digitalLayer, gaq: {} };
  }
  window.digitalLayer.gaq = payload;

  dispatch(pushToDigitalLayer(payload));
};
