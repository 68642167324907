// 9fbef606107a605d69c0edbcd8029e5d

import {
  LOAD_TRANSLATION,
  LOAD_TRANSLATION_FAILURE,
  LOAD_TRANSLATION_SUCCESS,
} from "../../../../actions";

const loadTranslation = (locale) => ({
  payload: locale,
  type: LOAD_TRANSLATION,
});

const loadTranslationSuccess = (locale, messages) => ({
  payload: { locale, messages },
  type: LOAD_TRANSLATION_SUCCESS,
});

const loadTranslationFailure = (locale, error) => ({
  error: true,
  payload: { locale, error },
  type: LOAD_TRANSLATION_FAILURE,
});

export const loadTranslationEffect = (locale) => (dispatch, _, { API }) => {
  dispatch(loadTranslation(locale));

  API.loadTranslation(locale)
    .then((messages) => dispatch(loadTranslationSuccess(locale, messages)))
    .catch((error) => dispatch(loadTranslationFailure(locale, error)));
};
