// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import RD from "../../../utils/functional/remote-data";
import {
  LOAD_CONFIGURATION,
  LOAD_CONFIGURATION_SUCCESS,
} from "../../../actions";
import { DEFAULT_FEATURE_TOGGLES } from "../../../utils/constants";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_CONFIGURATION:
      return RD.loading();

    case LOAD_CONFIGURATION_SUCCESS: {
      const { configuration } = payload;

      const countryFeatureToggles = configuration.featureToggles || {};

      const featureToggles = Object.entries(DEFAULT_FEATURE_TOGGLES).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            countryFeatureToggles[key] != null
              ? countryFeatureToggles[key]
              : value,
        }),
        {}
      );

      return RD.success(featureToggles);
    }

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
