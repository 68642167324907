// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  HIDE_MODAL_CONNECTOR_SELECTOR,
  HIDE_MODAL_ERROR,
  HIDE_MODAL_ERROR_CALLBACK_REMOTE,
  LOAD_CALLBACK_REMOTE_SLOTS_FAILURE,
  LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS,
  OPEN_MODAL_CONNECTOR_SELECTOR,
  POST_LEAD_FAILURE,
  POST_LEAD_SUCCESS,
  POST_LEAD,
} from "../../../actions";
import { MODALS } from "../../../utils/constants";

const INITIAL_STATE = null;

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case POST_LEAD_FAILURE:
      return MODALS.ERROR;

    case LOAD_CALLBACK_REMOTE_SLOTS_FAILURE:
      return MODALS.ERROR_CALLBACK_REMOTE;

    case LOAD_CALLBACK_REMOTE_SLOTS_SUCCESS:
    case OPEN_MODAL_CONNECTOR_SELECTOR:
      return MODALS.CONNECTOR_SELECTOR;

    case POST_LEAD:
      return state === MODALS.CONNECTOR_SELECTOR ? state : INITIAL_STATE;

    // The `MODALS.SUCCESS` that we set is here actually just for backwards compatibility with Analytics.
    //
    // In the past, we would show the success step as modal, and the Analytics team is checking this property
    // to idenfity when the form was submitted. So we keep it here, even though we do not show any success modal.
    case POST_LEAD_SUCCESS:
      return payload.status === "SUCCESS" ? MODALS.SUCCESS : MODALS.ERROR;

    case HIDE_MODAL_CONNECTOR_SELECTOR:
    case HIDE_MODAL_ERROR:
    case HIDE_MODAL_ERROR_CALLBACK_REMOTE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
