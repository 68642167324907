// 9fbef606107a605d69c0edbcd8029e5d

import { compose, prop } from "ramda";
import {
  POST_LEAD,
  POST_LEAD_FAILURE,
  POST_LEAD_RESET,
  POST_LEAD_SUCCESS,
  SET_CALLBACK_REMOTE_SUBMIT_ERROR,
  TRIGGER_CONTENT_INTERACTION_TRACKING,
} from "../../../../actions";
import * as reducers from "../../..";
import {
  DEFAULT_LEAD_META_VALUES,
  DEFAULT_PROFILE,
  LEAD_CALLBACK_REMOTE_ERROR_CODES,
  LEAD_CALLBACK_REMOTE_SUBMIT_ERRORS,
  LEAD_HTTP_STATUS_CODES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
  UTF_EVENT_TYPES,
} from "../../../../utils/constants";
import { trackContentInteraction } from "../../../../utils/track-utf";
import { getCorrelationIds } from "../../../../utils";
import RD from "../../../../utils/functional/remote-data";

const getLeadSource = (state) => {
  const leadSourceVariant =
    reducers.getSchedulerDisplay(state) ||
    reducers.getCallbackRemoteDisplay(state)
      ? "leadSourceCallback"
      : "leadSourceDefault";

  return compose(
    RD.withDefault(DEFAULT_LEAD_META_VALUES[leadSourceVariant]),
    RD.map(prop(leadSourceVariant))
  )(reducers.getLeadMetaValues(state));
};

const getSourceType = (state) =>
  compose(
    RD.withDefault(DEFAULT_LEAD_META_VALUES.sourceType),
    RD.map(prop("sourceType"))
  )(reducers.getLeadMetaValues(state));

const getProfile = (state) =>
  reducers.getProfile(state) && reducers.getProfile(state) !== DEFAULT_PROFILE
    ? reducers.getProfile(state)
    : null;

const getMetaInformation = (state) => ({
  abTesting: reducers.getAbTesting(state),
  campaignId:
    sessionStorage.getItem("cid") && `FSID-${sessionStorage.getItem("cid")}`,
  contactFormType: reducers.getContactFormType(state),
  language: reducers.getLanguage(state),
  marketCountry: reducers.getCountry(state),
  leadSource: getLeadSource(state),
  profile: getProfile(state),
  qualifier: reducers.getQualifier(state),
  sourceType: getSourceType(state),
  sourceUrl: window && window.location ? window.location.href : "",
  trackingId: reducers.getTrackingId(state),
});

const normalizeOptions = (product) => {
  return product.options &&
    !product.options.expedited &&
    !product.options.standard
    ? {
        ...product,
        options: {
          expedited: true,
          standard: true,
        },
      }
    : product;
};

const addShipmentScaleToProducts = (product) => {
  // TODO REMOVE IF NOT NEEDED
  // console.log(product);
  return product;
};

const normalizeShipmentProducts = (shipmentProduct) =>
  Object.entries(shipmentProduct).reduce(
    (products, [key, value]) =>
      !value.checked
        ? products
        : {
            ...products,
            [key.toUpperCase()]: compose(
              addShipmentScaleToProducts,
              normalizeOptions
            )(value),
          },
    {}
  );

const postLead = () => ({
  type: POST_LEAD,
});

const postLeadSuccess = (payload) => ({
  payload,
  type: POST_LEAD_SUCCESS,
});

const postLeadFailure = (payload) => ({
  error: true,
  payload,
  type: POST_LEAD_FAILURE,
});

const postLeadReset = () => ({
  type: POST_LEAD_RESET,
});

const setCallbackRemoteSubmitError = (payload) => ({
  payload,
  type: SET_CALLBACK_REMOTE_SUBMIT_ERROR,
});

const triggerContentInteractionTracking = () => ({
  type: TRIGGER_CONTENT_INTERACTION_TRACKING,
});

const triggerContentInteractionTrackingEffect = (utfName) => (
  dispatch,
  getState
) => {
  dispatch(triggerContentInteractionTracking());
  const profile = reducers.getProfile(getState());
  trackContentInteraction({
    context: profile,
    name: utfName,
    type: UTF_EVENT_TYPES.FORM,
    interaction: UTF_EVENT_INTERACTIONS.SUBMIT,
    position: UTF_EVENT_POSITIONS.CALLBACK_REMOTE,
  });
};

const getApiError = (data) => data?.errors?.[0]?.code;

export const postLeadEffect = () => (dispatch, getState, { API }) => {
  dispatch(postLead());

  const { contactInformation, shipmentProduct } = reducers.getRequestData(
    getState()
  );

  const body = {
    contactInformation,
    meta: getMetaInformation(getState()),
    shipmentProduct: normalizeShipmentProducts(shipmentProduct),
  };

  API.postLead(body, getCorrelationIds())
    .then(({ businessUnitDetails, status, uniqueID: uniqueId }) => {
      dispatch(
        postLeadSuccess({
          businessUnits: businessUnitDetails,
          status,
          uniqueId,
        })
      );
    })
    .catch((res) => {
      const callbackRemoteDisplay = reducers.getCallbackRemoteDisplay(
        getState()
      );
      if (
        callbackRemoteDisplay &&
        res?.status?.toString() === LEAD_HTTP_STATUS_CODES.ERROR
      ) {
        res?.json().then((data) => {
          switch (getApiError(data)) {
            case LEAD_CALLBACK_REMOTE_ERROR_CODES.SLOT_TAKEN: {
              dispatch(
                setCallbackRemoteSubmitError(
                  LEAD_CALLBACK_REMOTE_SUBMIT_ERRORS.SLOT_TAKEN
                )
              );
              dispatch(postLeadReset());
              dispatch(
                triggerContentInteractionTrackingEffect(
                  UTF_EVENT_NAMES.SLOT_TAKEN
                )
              );
              break;
            }

            case LEAD_CALLBACK_REMOTE_ERROR_CODES.SLOT_INVALID: {
              dispatch(
                setCallbackRemoteSubmitError(
                  LEAD_CALLBACK_REMOTE_SUBMIT_ERRORS.SLOT_INVALID
                )
              );
              dispatch(postLeadReset());
              dispatch(
                triggerContentInteractionTrackingEffect(
                  UTF_EVENT_NAMES.SLOT_INVALID
                )
              );
              break;
            }

            default: {
              dispatch(postLeadFailure(res));
            }
          }
        });
      } else {
        dispatch(postLeadFailure(res));
      }
    });
};
